import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import SectionHeader from '../../common/ui/section_header/SectionHeader';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FEATURE_TYPE,
  LOADING_MESSAGES,
  MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SCREEN_TITLE,
  WISH_TYPES,
} from '../../common/constants/AppConstants';
import { getFeatureComponentUrl, getFeatureComponentUrlLdpi } from '../../utils/accessibility';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import {
  COMMENT_BY_ID,
  DELETE_COMMENT,
  EDIT_COMMENT,
  GET_CELEBRATION_MESSAGES,
  GET_EMPLOYEE_BY_ID,
  GET_MY_WISHES_LIST,
  LIKE_UNLIKE_COMMENT,
} from '../../services/ApiUrls';
import { apiRequest } from '../../services/Service';
import { saveCommentCountData } from '../../state/CommentData';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
} from '../../state/UIState';
import {
  showToast,
  isEmptyText,
  formatDateDoMMM,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import DefaultBirthdayContentView from '../../common/ui/default_content_view/DefaultBirthdayContentView';
import CelebrationMessagesModal from '../../common/ui/profile_detail/components/CelebrationMessagesModal';
import { selectEmployeeData } from '../../state/EmployeeData';
import { selectConfigurations } from '../../state/MasterData';
import axios from 'axios';
import Lottie from 'react-lottie';
import CommentItemWishes from '../../common/screens/comment/component/CommentItemWishes';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { EditorState } from 'draft-js';
import {
  extractDataFromEditorState,
  getCommentMentionsState,
} from '../../common/components/hashtagsAndMentionsInput/hashtagUtils';
import CustomCommentBox from '../../common/ui/comment_box/CustomCommentBox';

const CelebrationWishesScreen = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageId, setPageId] = useState(1);
  const [comments, setComments] = useState([]);
  const [showDeleteLoader, setShowDeleteLoader] = useState(false);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const { celebrationDetails, year, fromRoute } = location.state;
  const [profileData, setProfileData] = useState(location.state.profileData ?? {});
  const accessibilityData = useSelector(selectAccessibilityData);
  const [modalVisible, setModalVisible] = useState(false);
  const [celebrationMessages, setCelebrationMessages] = useState({});
  const [wishesData, setWishesData] = useState({});
  const employeeData = useSelector(selectEmployeeData);
  const [postId, setPostId] = useState();
  const [lottieJSON, setLottieJSON] = useState('');
  const configurationsData = useSelector(selectConfigurations);
  const dashboardConfig = configurationsData._dashboard_config.celebrationLottie;
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [image, setImage] = useState('');
  const [mentions, setMentions] = useState([]);
  const [showLoadMore, setLoadmore] = useState(false);

  const callApi = useCallback(async () => {
    dispatch(saveShowEmptyContentView(true));
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      if (
        fromRoute == NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL ||
        fromRoute == NAVIGATION_ROUTES.MY_PROFILE_DETAIL
      ) {
        const formData = new URLSearchParams();
        formData.append('page_id', pageId);
        formData.append('employee_id', profileData.employee_id);
        formData.append('year', year);
        formData.append('type', JSON.stringify([celebrationDetails.type]));
        const [wishesApiResponse, celebrationMessageApiResponse] = await Promise.all([
          apiRequest(GET_MY_WISHES_LIST, REQUEST_TYPE.POST, formData),
          apiRequest(GET_CELEBRATION_MESSAGES + profileData.employee_id, REQUEST_TYPE.GET, {}),
        ]);
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(celebrationMessageApiResponse).length) {
          if (celebrationMessageApiResponse.response.status) {
            const data = celebrationMessageApiResponse.response.data.messages;
            setCelebrationMessages(data);
            if (`${profileData.employee_id}` !== `${employeeData.employee_id}`) {
              setModalVisible(true);
            } else {
              setModalVisible(false);
            }
          }
        }
        if (Object.keys(wishesApiResponse).length) {
          if (wishesApiResponse.response.status) {
            const data = wishesApiResponse.response.data;
            if (data.length > 0) setWishesData(data[0]);
            setPostId(data[0].post_id);
            callWishesApi(1, data[0].post_id);
          }
        }
      } else if (fromRoute == NAVIGATION_ROUTES.NOTIFICATION_LISTING) {
        const params = {
          type: 'WS',
          page_id: pageId,
        };
        const wishesApiResponse = await apiRequest(
          GET_MY_WISHES_LIST + '/post/' + celebrationDetails.post_id,
          REQUEST_TYPE.GET,
          params
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(wishesApiResponse).length) {
          if (wishesApiResponse.response.status) {
            const data = wishesApiResponse.response.data.wishes;
            if (Object.keys(data).length > 0) {
              setWishesData(data);
              setPostId(data.post_id);
              getEmployeeDataById(data.employee_id);
              callWishesApi(1, data.post_id);
              getCelebrationMessages(data.employee_id);
            }
          }
        }
      } else {
        setPageId(1);
        setPostId(celebrationDetails.post_id);
        callWishesApi(1, celebrationDetails.post_id);
        getCelebrationMessages(profileData.employee_id);
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, celebrationDetails.type, year]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.WISHES_CELEBRATIONS, NAVIGATION_ROUTES.CELEBRATION_WISHES);
  }, []);

  const getCelebrationMessages = async (employeeId) => {
    const celebrationMessageApiResponse = await apiRequest(
      GET_CELEBRATION_MESSAGES + employeeId,
      REQUEST_TYPE.GET,
      {}
    );
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    if (Object.keys(celebrationMessageApiResponse).length) {
      if (celebrationMessageApiResponse.response.status) {
        const data = celebrationMessageApiResponse.response.data.messages;
        setCelebrationMessages(data);
      }
    }

    if (`${profileData.employee_id}` !== `${employeeData.employee_id}`) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  };

  const getEmployeeDataById = async (employeeId) => {
    const apiResponse = await apiRequest(GET_EMPLOYEE_BY_ID + employeeId, REQUEST_TYPE.GET, {});
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    if (Object.keys(apiResponse).length) {
      if (apiResponse.response.status) {
        if (Object.keys(apiResponse.response.data.employee).length) {
          setProfileData(apiResponse.response.data.employee);
        }
      }
    }
  };

  useEffect(() => {
    if (profileData?.employee_id && employeeData?.employee_id) {
      if (`${profileData.employee_id}` !== `${employeeData.employee_id}`) {
        setModalVisible(true);
      } else {
        setModalVisible(false);
      }
    }
  }, [profileData, employeeData]);

  useEffect(() => {
    if (lottieJSON === '') {
      if (dashboardConfig) {
        const fileBaseUrl = dashboardConfig.animation_file_base_url_web;
        const filePath = dashboardConfig.animation_file_path;
        if (fileBaseUrl && filePath) {
          axios({
            method: REQUEST_TYPE.GET,
            url: fileBaseUrl + filePath,
          })
            .then((response) => {
              if (response.status === 200) {
                if (response.data) {
                  setLottieJSON(response.data);
                }
              }
            })
            .catch(() => {});
        }
      }
    }
  }, [dashboardConfig]);

  const callWishesApi = useCallback(
    async (pageId, post_id = postId) => {
      if (post_id) {
        let params = {
          page_id: pageId,
          type: FEATURE_TYPE.WISHES,
        };
        if (pageId === 1) {
          const commentsApiResponse = await apiRequest(
            COMMENT_BY_ID + post_id,
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(commentsApiResponse).length) {
            if (commentsApiResponse.response.status) {
              const data = commentsApiResponse.response.data.feeds;
              if (data.length) {
                setComments(data);
                setPageId(pageId);
                setLoadmore(true);
              }
            }
          }
        } else {
          const commentsApiResponse = await apiRequest(
            COMMENT_BY_ID + post_id,
            REQUEST_TYPE.GET,
            params
          );
          if (Object.keys(commentsApiResponse).length) {
            if (commentsApiResponse.response.status) {
              const data = commentsApiResponse.response.data.feeds;
              const commentsData = commentsApiResponse.response.data.feeds?.reverse();
              if (data.length) {
                setComments((prevComments) => {
                  const updatedComments = commentsData.map((newComment) => {
                    const existingCommentIndex = prevComments.findIndex(
                      (prevComment) => prevComment.comment_id === newComment.comment_id
                    );
                    if (existingCommentIndex !== -1) {
                      const updatedComment = {
                        ...newComment,
                        ...prevComments[existingCommentIndex],
                      };
                      prevComments[existingCommentIndex] = updatedComment;
                    } else {
                      prevComments.unshift(newComment);
                    }
                    return newComment;
                  });

                  return [...prevComments];
                });
                setLoadmore(true);
                setPageId(pageId);
              } else {
                setLoadmore(false);
              }
            }
          }
        }
      }
    },
    [postId, pageId]
  );

  useEffect(() => {
    callApi();
  }, [callApi]);

  const sendCommentFunction = async () => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    let tempDes = description;
    tempDes = tempDes.replace(/ {2,}(?![\n])/g, ' ');

    addedMentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    mentions.map((user) => {
      tempDes = tempDes.replace(user.name, `<rsu>{{${user.employee_id}}}</rsu> `);
    });

    if (image != '') {
      tempDes = tempDes + ` <egami>${image}</egami>`;
    }

    if (!isEmptyText(tempDes)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const formData = new URLSearchParams();
        formData.append('type', FEATURE_TYPE.WISHES);
        formData.append('comment', tempDes);
        const apiResponse = await apiRequest(COMMENT_BY_ID + postId, REQUEST_TYPE.POST, formData);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response) {
              setEditorState(EditorState.createEmpty());
              setImage('');
              dispatch(saveCommentCountData(apiResponse.response.data.comment_count));
              showToast(apiResponse.response.message);
              setPageId(1);
              callWishesApi(1, postId);
            }
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  const undoCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'undo');
  };

  const showToastWithMessage = (commentId, message) => {
    showToast(
      <div className="d-flex flex-row common-comment-toast-row">
        <div className="common-comment-toast-text">{message}</div>
        <div
          className="common-comment-toast-button"
          onClick={() => {
            undoCommentFunction(commentId);
          }}>
          UNDO
        </div>
      </div>
    );
  };

  const deleteCommentFunction = (commentId) => {
    deleteCommentApi(commentId, 'delete');
  };

  const deleteCommentApi = async (commentId, type) => {
    try {
      setShowDeleteLoader(true);
      const formData = new URLSearchParams();
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + DELETE_COMMENT,
        REQUEST_TYPE.POST,
        formData
      );
      setShowDeleteLoader(false);
      if (apiResponse.response.status) {
        setPageId(1);
        const updatedComment = removeComment(comments, commentId);
        setComments(updatedComment);
        if (type === 'delete') {
          showToastWithMessage(commentId, apiResponse.response.message);
        }
      }
    } catch (err) {
      setShowDeleteLoader(false);
    }
  };

  const editCommentFunction = async (commentId, comment) => {
    if (!isEmptyText(comment)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
            progressMessage: LOADING_MESSAGES.DEFAULT,
          })
        );
        const formData = new URLSearchParams();
        formData.append('comment', comment);
        const apiResponse = await apiRequest(
          COMMENT_BY_ID + commentId + '/' + EDIT_COMMENT,
          REQUEST_TYPE.POST,
          formData
        );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (apiResponse.response.status) {
          showToast(apiResponse.response.message);
          if (apiResponse.response.comments) {
            setPageId(1);
            const updatedData = apiResponse.response?.updated_info?.feed;
            const previousComments = comments;
            const updatedComments = previousComments.map((comment) => {
              const updatedComment = updatedData.find(
                (updated) => updated.comment_id === comment.comment_id
              );

              if (updatedComment) {
                return { ...comment, ...updatedComment };
              }
              return comment;
            });
            setComments(updatedComments);
            //callWishesApi(1, postId);
          }
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    }
  };

  function removeComment(comments, targetCommentId) {
    comments.forEach((parentComment) => {
      parentComment.child_comments = parentComment.child_comments.filter(
        (child) => child.comment_id !== targetCommentId
      );
      if (parentComment.comment_id === targetCommentId) {
        const index = comments.indexOf(parentComment);
        if (index !== -1) {
          comments.splice(index, 1);
        }
      }
    });
    for (let parentComment of comments) {
      parentComment.child_comments = parentComment.child_comments.filter(
        (child) => child.comment_id !== targetCommentId
      );
    }
    return comments;
  }
  const likeUnlikeCommentApi = async (commentId) => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.LIKE_UPDATE,
        })
      );
      const apiParams = new URLSearchParams();
      apiParams.append('component_refer_id', postId);
      apiParams.append('type', FEATURE_TYPE.WISHES);
      const apiResponse = await apiRequest(
        COMMENT_BY_ID + commentId + '/' + LIKE_UNLIKE_COMMENT,
        REQUEST_TYPE.POST,
        apiParams
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length) {
        if (apiResponse.response.status) {
          const message = apiResponse.response.message;
          if (message) {
            const updatedData = apiResponse.response?.updated_info?.feed;
            const previousComments = comments;
            const updatedComments = previousComments.map((comment) => {
              const updatedComment = updatedData.find(
                (updated) => updated.comment_id === comment.comment_id
              );

              if (updatedComment) {
                return { ...comment, ...updatedComment };
              }
              return comment;
            });
            setComments(updatedComments);
            showToast(message);
          }
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressWhoLikedHandler = (commentId) => {
    history.push(NAVIGATION_ROUTES.LIKED_PEOPLE, {
      referId: postId,
      parentType: FEATURE_TYPE.WISHES,
      commentId: commentId,
    });
  };

  const renderComments = () => {
    if (comments && comments.length > 0) {
      return comments.map((item) => {
        return (
          <CommentItemWishes
            key={item.wish_id}
            comments={comments}
            setComments={setComments}
            commentId={item.comment_id}
            date={item.comment_on}
            employee={item.employee}
            commentReplies={item.child_comments}
            likeType={FEATURE_TYPE.WISHES}
            referId={postId}
            callCommentsApi={callWishesApi}
            commentLikeCount={item.like_count}
            commentHasLiked={item.liked}
            onPressWhoLikedHandler={onPressWhoLikedHandler}
            likeUnlikeCommentApi={likeUnlikeCommentApi}
            message={item.comment.replace(/\n/gi, '')}
            showDeleteLoader={showDeleteLoader}
            editComment={(commentId, commentText) => {
              editCommentFunction(commentId, commentText);
            }}
            deleteComment={(commentId) => {
              deleteCommentFunction(commentId);
            }}
            mentions={item.taggged_employees}
            commentText={item.comment_text}
          />
        );
      });
    }
  };

  const getSubText = () => {
    if (
      fromRoute == NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL ||
      fromRoute == NAVIGATION_ROUTES.NOTIFICATION_LISTING
    ) {
      switch (celebrationDetails.type) {
        case WISH_TYPES.BIRTHDAY:
          return `Birthday: ${formatDateDoMMM(wishesData.event_date)}`;
        case WISH_TYPES.ADVANCEMENT:
          return `Advancement: ${formatDateDoMMM(wishesData.event_date)}`;
        case WISH_TYPES.ANNIVERSARY:
          return `Service Anniversary: ${formatDateDoMMM(wishesData.event_date)}`;
        case WISH_TYPES.NEW_JOINEE:
          return `New joinee: ${formatDateDoMMM(wishesData.event_date)}`;
        default:
          return `Birthday: ${formatDateDoMMM(wishesData.event_date)}`;
      }
    } else {
      switch (celebrationDetails.type) {
        case WISH_TYPES.BIRTHDAY:
          return `Birthday: ${formatDateDoMMM(celebrationDetails.event_date)}`;
        case WISH_TYPES.ADVANCEMENT:
          return `Advancement: ${formatDateDoMMM(celebrationDetails.event_date)}`;
        case WISH_TYPES.ANNIVERSARY:
          return `Service Anniversary: ${formatDateDoMMM(celebrationDetails.event_date)}`;
        case WISH_TYPES.NEW_JOINEE:
          return `New joinee: ${formatDateDoMMM(celebrationDetails.event_date)}`;
        default:
          return `Birthday: ${formatDateDoMMM(celebrationDetails.event_date)}`;
      }
    }
  };

  const getSectionHeader = () => {
    switch (celebrationDetails.type) {
      case WISH_TYPES.BIRTHDAY:
        return SCREEN_TITLE.BIRTHDAY;
      case WISH_TYPES.ADVANCEMENT:
        return SCREEN_TITLE.ADVANCEMENT;
      case WISH_TYPES.ANNIVERSARY:
        return SCREEN_TITLE.ANNIVERSARY;
      case WISH_TYPES.NEW_JOINEE:
        return SCREEN_TITLE.NEW_JOINEE;
      default:
        return SCREEN_TITLE.BIRTHDAY;
    }
  };

  const onToggleModal = () => {
    setModalVisible(!modalVisible);
  };

  const onChangeTextInput = (val) => {
    const { description, addedMentions } = extractDataFromEditorState(editorState);
    const editorState2 = getCommentMentionsState(description + val, [...addedMentions]);
    setEditorState(editorState2);
  };

  const onSelectWish = (val) => {
    const editorState2 = getCommentMentionsState(val, []);
    setEditorState(editorState2);
  };

  const onMention = (mention) => {
    setMentions((prev) => [...prev, mention]);
  };

  const getMessagesFromWishType = () => {
    let messages = [];
    if (celebrationDetails.type === WISH_TYPES.BIRTHDAY) {
      messages = celebrationMessages.BIRTHDAY;
    } else if (celebrationDetails.type === WISH_TYPES.ANNIVERSARY) {
      messages = celebrationMessages.ANNIVERSARY;
    } else if (celebrationDetails.type === WISH_TYPES.ADVANCEMENT) {
      messages = celebrationMessages.ADVANCEMENT;
    } else if (celebrationDetails.type === WISH_TYPES.NEW_JOINEE) {
      messages = celebrationMessages.NEWJOINEE;
    }
    return messages;
  };

  const onPressLoadPrevious = async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    await callWishesApi(pageId + 1);
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
  };
  const onPressMore = async () => {
    dispatch(saveProgressLoadingState({ isProgressLoading: true }));
    await callWishesApi(1);
    dispatch(saveProgressLoadingState({ isProgressLoading: false }));
  };
  const emptyListView = () => {
    return (
      <div>
        {profileData.employee_id == employeeData.employee_id ? (
          <DefaultContentView message={MESSAGES.NO_WISHES} />
        ) : (
          <DefaultBirthdayContentView
            message={`Be the first to wish ${profileData.first_name ?? ''} ${
              profileData.last_name ?? ''
            }`}
            type={celebrationDetails.type}
          />
        )}
      </div>
    );
  };

  return (
    <div className="container">
      <SectionHeader title={getSectionHeader()} />
      <BackButton />
      {employeeData.employee_id !== profileData.employee_id && (
        <CelebrationMessagesModal
          modalVisible={modalVisible && Object.keys(celebrationMessages).length > 0}
          toggleModal={onToggleModal}
          onChangehandler={onSelectWish}
          data={getMessagesFromWishType()}
        />
      )}
      <div className="py-3 mx-auto common-container-md">
        {fromRoute == NAVIGATION_ROUTES.ALL_WISHES && celebrationDetails.image ? (
          <img
            src={getUrlWithSpecificRendition(
              celebrationDetails.image.base_url + '/',
              SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
              celebrationDetails.image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
            alt="All Wishes"
          />
        ) : (
          wishesData &&
          wishesData.image && (
            <>
              <img
                src={getUrlWithSpecificRendition(
                  wishesData.image.base_url + '/',
                  SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                  wishesData.image.image_path
                )}
                className="embed-responsive embed-responsive-16by9"
                alt="Profile"
              />
            </>
          )
        )}
        <div className="mx-auto">
          <div className="employee-details-container">
            {Object.keys(profileData).length > 0 && (
              <div className="wishes-employee-details">
                <img
                  className={`d-block common-user-profile-photo-sm mr-3`}
                  src={
                    profileData.profile_picture.image_path
                      ? getUrlWithSpecificRendition(
                          profileData.profile_picture.base_url,
                          SCREEN_DENSITY_QUALIFIERS.LDPI,
                          profileData.profile_picture.image_path
                        )
                      : getFeatureComponentUrlLdpi(
                          accessibilityData,
                          ACCESSIBILITY_FEATURE_COMPONENTS.NEW_JOINEES_DEFAULT_PROFILE,
                          ACCESSIBILITY_IDENTIFIERS.NEW_JOINEES
                        )
                  }
                />
                <div className="profile-lottie-container">
                  <div>
                    <div className="communities-post-employee-name">{`${profileData.first_name} ${profileData.middle_name} ${profileData.last_name}`}</div>
                    <div className="wishes-sub-text">{getSubText()}</div>
                  </div>
                  <div
                    style={{
                      background: dashboardConfig ? dashboardConfig.gradient_colors_web : '',
                    }}>
                    {lottieJSON !== '' && (
                      <Lottie
                        options={{
                          loop: true,
                          autoplay: true,
                          animationData: lottieJSON,
                        }}
                        isClickToPauseDisabled
                        style={{ height: 60 }}
                      />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="wishes-heading-container">
          <div className="col-2 col-sm-1 d-flex justify-content-end">
            <img
              src={getFeatureComponentUrl(
                accessibilityData,
                ACCESSIBILITY_FEATURE_COMPONENTS.HOMECREEN_LEFT_ICON_URL,
                ACCESSIBILITY_IDENTIFIERS.CELEBRATIONS
              )}
              className="home-section-left-icon"
            />
          </div>
          <div className="wishes-heading-celebrations">Wishes</div>
        </div>
        <div>
          {comments.length > 0 && showLoadMore && (
            <div className="mb-2 mt-4">
              <div className="loadMoreContainer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                    ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                  )}
                  className="load-more-icon mr-2"
                  onClick={onPressLoadPrevious}
                />
                <div className="loadmoreText" onClick={onPressLoadPrevious}>
                  Load previous comments
                </div>
              </div>
            </div>
          )}

          {!comments.length && !progressLoadingState.isProgressLoading ? (
            emptyListView()
          ) : (
            <div>{renderComments()}</div>
          )}
          {comments.length > 0 && showLoadMore && (
            <div className="mb-2 mt-4">
              <div onClick={onPressMore} className="loadMoreContainer">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                    ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                  )}
                  className="load-more-icon mr-2"
                />
                <div className="loadmoreText">Load latest comment</div>
              </div>
            </div>
          )}
        </div>
        <CustomCommentBox
          onChangehandler={onChangeTextInput}
          getCommentText={sendCommentFunction}
          showLoader={progressLoadingState.isProgressLoading}
          iconName={fromRoute != NAVIGATION_ROUTES.MY_PROFILE_DETAIL && 'wishes'}
          openWishDialog={onToggleModal}
          setEditorState={setEditorState}
          editorState={editorState}
          enableUserMentions={true}
          enableHashtags={false}
          enableImageUpload={true}
          image={image}
          setImage={setImage}
          onMention={onMention}
        />
      </div>
    </div>
  );
};

export default CelebrationWishesScreen;
