import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_DENSITY_QUALIFIERS,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../common/constants/AppConstants';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../common/ui/load_more_btn/LoadMoreBtn';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { ARTICLES, INCREASE_VIEW_COUNT_EXTERNAL_LINK } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { selectAccessibilityData } from '../../state/AccessibilityData';
import {
  saveProgressLoadingState,
  saveSearchText,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectSearchText,
  selectShowEmptyContentView,
} from '../../state/UIState';
import { getHeaderComponentUrl } from '../../utils/accessibility';
import {
  formatDateDDMonthYYYY,
  formatSecondsIntoMinutes,
  formatSecondsIntoTimeForVideos,
  getUrlWithSpecificRendition,
} from '../../utils/common';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import { selectDiTabData } from '../../state/TabData';
import { selectDI } from '../../state/MasterData';
import BackButton from '../../common/ui/common_back_button/BackButton';
import PlayIconOverlay from '../../common/ui/play_icon_overlay/PlayIconOverlay';
import debounce from 'lodash.debounce';
import ActivityLoader from '../../common/ui/activity_loader/ActivityLoader';

const DISearchScreen = ({ history, location }) => {
  const dispatch = useDispatch();
  const [searchList, setSearchList] = useState([]);
  const loadMoreFlag = useRef(false);
  const [loadMoreBtn, setLoadMoreBtn] = useState(false);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const diTabData = useSelector(selectDiTabData);
  const diTitles = useSelector(selectDI);
  let selectedTab = diTitles.find((o) => o.key === diTabData);
  const [isSearchEnabled, setIsSearchEnabled] = useState(true);
  const [quickSearchLoading, setQuickSearchLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);

  const callInitiativeSearchApi = useCallback(
    async (pageId, text, isQuickSearch = false) => {
      dispatch(saveShowEmptyContentView(true));
      if (isQuickSearch) {
        setQuickSearchLoading(true);
      } else {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
      }
      if (loadMoreFlag.current) {
        setLoadMoreBtn(true);
        const params = {
          page_id: pageId,
          query: text,
          channel_id: selectedTab?.channel_id,
          sub_channel_id: selectedTab?.sub_channel_id,
        };
        try {
          const apiResponse = await apiRequest(ARTICLES, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                if (pageId === 1) {
                  setSearchList(apiResponse.response.data.feeds);
                } else {
                  setSearchList((oldArray) => [...oldArray, ...apiResponse.response.data.feeds]);
                }
                loadMoreFlag.current = true;
              } else {
                loadMoreFlag.current = false;
                setLoadMoreBtn(false);
              }
            }
          }
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        } catch (err) {
          console.log(err);
          handleError(err, params, ARTICLES, NAVIGATION_ROUTES.DI_SEARCH);
          setQuickSearchLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          //
        }
      }
    },
    [dispatch, selectedTab?.channel_id, selectedTab?.sub_channel_id]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setPageNo(1);
      loadMoreFlag.current = true;
      callInitiativeSearchApi(1, event.target.value, true);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const callSubmitFunction = (event) => {
    event?.preventDefault();
    if (searchText !== '') {
      dispatch(saveSearchText(searchText));
      logEvent(ANALYTICS_EVENT_TYPES.DI_SEARCH, searchText, ANALYTICS_ITEM_NAMES.SEARCH);
      setSearchList([]);
      setPageNo(1);
      loadMoreFlag.current = true;
      callInitiativeSearchApi(1, searchText);
    }
  };

  const onPressItem = async (item) => {
    if (item.content_load_type === '1') {
      try {
        const es = await apiRequest(
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          REQUEST_TYPE.GET,
          {}
        );
      } catch (err) {
        handleError(
          err,
          {},
          INCREASE_VIEW_COUNT_EXTERNAL_LINK + item?.article_id,
          NAVIGATION_ROUTES.DI_SEARCH
        );
        //ignore if the view count request is not successful
        console.warn(err);
      }
      window.open(item.url);
    } else {
      history.push(`${NAVIGATION_ROUTES.DI}/${item.article_id}`);
    }
  };

  const onPressLoadMore = () => {
    callInitiativeSearchApi(pageNo + 1, searchText);
    setPageNo(pageNo + 1);
  };

  const resetValue = () => {
    history.replace(NAVIGATION_ROUTES.DI_SEARCH);
    setIsSearchEnabled(false);
    setSearchText('');
    dispatch(saveSearchText(''));
    setPageNo(1);
    callInitiativeSearchApi(1, '');
    loadMoreFlag.current = true;
  };

  const onClickSearchHandler = () => {
    setIsSearchEnabled(true);
  };

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.DI_SEARCH, NAVIGATION_ROUTES.DI_SEARCH);
  }, []);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.SEARCH_NO_RESULTS} />;
  };

  const renderItem = (item) => {
    return (
      <div
        key={`${item.article_id}`}
        className="col-sm-4 mb-2 common-cursor-pointer"
        onClick={() => onPressItem(item)}>
        <div>
          {item?.content_load_type === '3' && <PlayIconOverlay />}
          <img
            src={getUrlWithSpecificRendition(
              item.image.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item.image.image_path
            )}
            className="embed-responsive embed-responsive-16by9"
          />
          <div className="row ml-1 mt-1">
            <div className="d-flex flex-row justify-content-between w-100 pr-3">
              {!!item?.time_to_read && (
                <div className="broadcast-event-date-new ">
                  {item.time_to_read != 0 ? formatSecondsIntoMinutes(item.time_to_read) : null}
                </div>
              )}
              {!!item?.video_duration &&
                item?.content_load_type === '3' &&
                item?.video_duration > 0 && (
                  <div className="broadcast-event-date-new ">
                    {formatSecondsIntoTimeForVideos(item?.video_duration)}
                  </div>
                )}
              <div className="broadcast-event-date-new ">
                {formatDateDDMonthYYYY(item.pub_datetime)}
              </div>
              {!!item.page_views && (
                <div className="broadcast-event-date-new ">
                  {item.page_views > 1 ? `${item.page_views} views` : `${item.page_views} view`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="di-title">{item.title}</div>
      </div>
    );
  };
  useEffect(() => {
    if (searchText) {
      callSubmitFunction();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.hash) {
      let hashtag = location?.hash || '';
      setSearchText(hashtag);
      loadMoreFlag.current = true;
      callInitiativeSearchApi(1, hashtag);
    }
  }, [callInitiativeSearchApi, location]);

  return (
    <div className="container">
      <div className="gcdo-header-row header-view-absolute justify-content-end float-right w-50">
        <div
          className={
            isSearchEnabled ? 'search-bar-with-icon search-bar-border' : 'search-bar-with-icon'
          }>
          {isSearchEnabled ? (
            <form className="directory-search-bar" onSubmit={callSubmitFunction}>
              <input
                autoFocus
                className="ml-2 directory-search-bar"
                type="text"
                placeholder="Search.."
                onChange={onInputChange}
                value={searchText}
              />
            </form>
          ) : (
            <div className="directory-search-bar" />
          )}
          {isSearchEnabled ? (
            <div className="gcdo-icon-row mr-2" onClick={resetValue}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
                )}
                className="header-icon"
              />
            </div>
          ) : (
            <div className="gcdo-icon-row mr-2" onClick={onClickSearchHandler}>
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_HEADER_COMPONENTS.SEARCH
                )}
                className="header-icon"
              />
            </div>
          )}
        </div>
      </div>
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.DNI} />
      <BackButton />

      <div className="main-content-container">
        <ActivityLoader visible={quickSearchLoading} />
        {showEmptyContentView && !searchList.length && !progressLoadingState.isProgressLoading ? (
          emptyListView()
        ) : (
          <div>
            <div className="row">{searchList.map((item) => renderItem(item))}</div>
            {loadMoreBtn && (
              <LoadMoreBtn
                onClick={onPressLoadMore}
                isLoading={progressLoadingState.isProgressLoading}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DISearchScreen;
