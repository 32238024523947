import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { callBookingsApi } from '../action';
import { selectEmployeeData } from '../../../state/EmployeeData';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import dayjs from 'dayjs';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
} from '../../../common/constants/AppConstants';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { useHistory } from 'react-router';
import StatusIndicator from '../../../common/components/StatusIndicator';
import { saveBookingData, selectBookingData } from '../../../state/SeatBookingData';

const MyBookingsTab = () => {
  const selectedType = useSelector(selectBookingData) ?? 'upcoming';
  const dispatch = useDispatch();
  const employeeData = useSelector(selectEmployeeData);
  const [showEmptyView, setShowEmptyView] = useState(false);
  const pageIdRef = useRef(1);
  const [dataLength, setDataLength] = useState(0);
  const loadMoreFlagRef = useRef(false);
  const [bookings, setBookings] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const history = useHistory();

  const findMyBookings = useCallback(
    async (type) => {
      try {
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );
        setShowEmptyView(false);
        const get_params = {
          page_id: pageIdRef.current,
          tab_type: type,
        };

        const response = await callBookingsApi(employeeData.employee_id, get_params);
        if (Object.keys(response).length > 0) {
          if (response.response.statusCode === 200) {
            if (response.response.data) {
              const data = response.response.data;
              setDataLength(data.length);
              if (data?.length === 0) {
                setShowEmptyView(true);
              }
              if (data.length > 0) {
                loadMoreFlagRef.current = true;
                if (pageIdRef.current === 1) {
                  setBookings(data);
                } else {
                  setBookings((oldArray) => [...oldArray, ...data]);
                }
              } else {
                loadMoreFlagRef.current = false;
              }
            }
          } else {
            setShowEmptyView(true);
          }
        }

        dispatch(resetLoaderState());
      } catch (error) {
        setShowEmptyView(true);
        dispatch(resetLoaderState());
        console.error(error);
        return null;
      }
    },
    [dispatch, employeeData.employee_id]
  );

  useEffect(() => {
    if (pageIdRef.current !== 1) {
      loadMoreFlagRef.current = false;
    }
  }, [dataLength]);

  useEffect(() => {
    pageIdRef.current = 1;
    findMyBookings(selectedType);
  }, [findMyBookings, selectedType]);

  const renderTypeTabSwitch = () => {
    return (
      <div className="d-flex mb-3 toggle-button-container">
        {selectedType === 'upcoming' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill toggle-selected">
            <div className="toggle-selected-text">UPCOMING</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              pageIdRef.current = 1;
              loadMoreFlagRef.current = true;
              setBookings([]);
              dispatch(saveBookingData('upcoming'));
            }}>
            <div className="toggle-unselected-text">UPCOMING</div>
          </div>
        )}
        {selectedType === 'all' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill toggle-selected">
            <div className="toggle-selected-text">ALL</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              pageIdRef.current = 1;
              loadMoreFlagRef.current = true;
              setBookings([]);
              dispatch(saveBookingData('all'));
            }}>
            <div className="toggle-unselected-text">ALL</div>
          </div>
        )}
      </div>
    );
  };

  const mapRedirect = (link) => {
    window.open(link, '_blank').focus();
  };

  const bookingInfoCard = (item) => {
    const formatDate = (date) => {
      if (!date) {
        null;
      }
      const now = dayjs().local();
      const formattedDate = date.format('DD MMMM YYYY');

      if (date.isSame(now, 'day')) {
        return `Today, ${formattedDate}`;
      } else if (date.isSame(now.add(1, 'day'), 'day')) {
        return `Tomorrow, ${formattedDate}`;
      } else {
        return `${date.format('dddd')}, ${formattedDate}`;
      }
    };
    const entryDatetime = item?.entry_datetime ? dayjs(item.entry_datetime) : null;
    const exitDatetime = item?.exit_datetime ? dayjs(item.exit_datetime) : null;

    const checkInTime = entryDatetime ? entryDatetime.format('hh:mm A') : 'N/A';
    const checkOutTime = exitDatetime ? exitDatetime.format('hh:mm A') : 'N/A';
    const formattedDate = entryDatetime ? formatDate(entryDatetime) : 'N/A';
    return (
      <div className="booking-card-container">
        <div
          className="common-cursor-pointer"
          onClick={() => {
            history.push(NAVIGATION_ROUTES.VEHICLE_BOOKING_PREVIEW_SCREEN, {
              fromRoute: 'BookingScreen',
              checkInTime: checkInTime,
              checkOutTime: checkOutTime,
              parkingNumber: item.spaceData.name,
              selectedDate: formattedDate,
              entryDatetime: entryDatetime,
              exitDatetime: exitDatetime,
              space_id: item.spaceData._id,
              booking_id: item.booking_id,
              selectedType: selectedType,
            });
          }}>
          <div className="p-2 d-flex flex-row justify-content-between">
            <div className="base-14px-font-semi-bold">{formattedDate}</div>
            <div className="d-flex flex-row justify-content-end align-items-center">
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_RIGHT_ARROW,
                  ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                )}
                className="seat-booking-book-tab-arrow-style common-cursor-pointer"
              />
            </div>
          </div>
          <div className="seat-booking-divider" />
          <div className="p-2">
            <div className="d-flex flex-row justify-content-between pb-1">
              <div className="base-14px-font">Check-in Time</div>
              <div className="base-14px-font">{checkInTime}</div>
            </div>
            <div className="d-flex flex-row justify-content-between pb-1">
              <div className="base-14px-font">Check-out Time</div>
              <div className="base-14px-font">{checkOutTime}</div>
            </div>
            <div className="d-flex flex-row justify-content-between pb-1">
              <div className="base-14px-font">Facility</div>
              <div className="base-14px-font">{item.facilityTitle}</div>
            </div>
            <div className="d-flex flex-row justify-content-between pb-1">
              <div className="base-14px-font">Level</div>
              <div className="base-14px-font">{item.levelName}</div>
            </div>
            <div className="d-flex flex-row justify-content-between pb-1">
              <div className="base-14px-font">Parking Number</div>
              <div className="base-14px-font">{item.spaceData.name}</div>
            </div>
          </div>
        </div>
        <div className="seat-booking-divider" />
        <div className="p-2">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div
              className="base-14px-font-underlined common-cursor-pointer"
              onClick={() => {
                mapRedirect(item.facilityUrl);
              }}>
              Find on map
            </div>
            {item.status && <StatusIndicator status={item.status} />}
          </div>
        </div>
      </div>
    );
  };

  const onPressLoadMore = () => {
    if (loadMoreFlagRef.current) {
      pageIdRef.current = pageIdRef.current + 1;
      findMyBookings(selectedType);
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_BOOKINGS} />;
  };

  return (
    <div>
      {showEmptyView && bookings?.length === 0 && emptyListView()}
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <div className="d-flex justify-content-center">{renderTypeTabSwitch()}</div>
      </div>

      <div className="grid-container">
        {bookings.map((item, index) => {
          return <div key={`${index}`}>{bookingInfoCard(item)}</div>;
        })}
      </div>
      <div className="mt-2">
        {loadMoreFlagRef.current === true && <LoadMoreBtn onClick={onPressLoadMore} />}
      </div>
    </div>
  );
};

export default MyBookingsTab;
