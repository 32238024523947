import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NAVIGATION_ROUTES } from '../constants/AppConstants';
import CommonGalleryImageComponent from './CommonGalleryImageComponent';
import Divider from '../ui/divider/Divider';

const CommonGalleryItem = ({ item, title, baseUrl, identifier, apiUrl, featureParams }) => {
  const [images, setImages] = useState([]);
  const [countLeft, setCountLeft] = useState(0);
  const history = useHistory();

  const gotoPictures = () => {
    history.push(NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN, {
      identifier: identifier,
      title: title,
      apiUrl: apiUrl,
      data: item,
      baseUrl: baseUrl,
      featureParams: featureParams || null,
    });
  };

  useEffect(() => {
    if (item.length < 7) {
      setImages(item);
      setCountLeft(0);
    } else {
      setImages(item.slice(0, 6));
      setCountLeft(item.length - 6);
    }
  }, [item]);
  return (
    <div>
      {title && <div className="breakouts-gallery-date mb-1">{title}</div>}
      <div className={`ml-35-images-wrapper mb-2`}>
        {images.map((imageItem, index) => {
          return (
            <div key={`${index}`} style={{ position: 'relative' }}>
              <CommonGalleryImageComponent
                key={`${index}`}
                item={imageItem}
                index={index}
                fromRoute={'mldayGallery'}
                baseUrl={baseUrl}
              />
              {index === 5 && countLeft > 0 && (
                <div onClick={() => gotoPictures()} className="ml-35-more-photos-bg-view">
                  <div className="breakouts-count-left-text">{`+${countLeft} ${
                    countLeft === 1 ? 'photo' : 'photos'
                  }`}</div>
                </div>
              )}
            </div>
          );
        })}
      </div>
      <Divider style="mt-3 mb-3" />
    </div>
  );
};

export default CommonGalleryItem;
