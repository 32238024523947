import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectEmployeeData } from '../../state/EmployeeData';
import { saveProgressLoadingState } from '../../state/UIState';
import {
  getDashboardFacilityData,
  getQuickBookingApi,
  getTipsApi,
  getUpcomingBookingApi,
} from './action';
import { Carousel } from 'react-bootstrap';
import { selectVehicleParkingConfig } from '../../state/MasterData';
import UpcomingItem from './components/UpcomingItem';
import QuickBookingItem from './components/QuickBookingItem';
import OfficeItem from './components/OfficeItem';
import HelpfulTipsItem from './components/HelpfulTipsItem';
import Divider from '../../common/ui/divider/Divider';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import { useHistory } from 'react-router';
import { NAVIGATION_ROUTES } from '../../common/constants/AppConstants';

const VehicleParkingHomeTab = () => {
  const vehicleHomeConfig = useSelector(selectVehicleParkingConfig);
  const [currentActiveSlideIndex, setCurrentActiveSlideIndex] = useState(0);
  const dispatch = useDispatch();
  const [facilities, setFacilities] = useState();
  const [facilitiesDropDownData, setFacilitiesDropDownData] = useState(
    facilities
      ? Object.values(facilities)
          .flat()
          .map((facility) => ({
            label: facility.title,
            value: facility.facility_id,
            link: facility.link,
            address: facility.address,
          }))
      : []
  );
  const [upcomingBookings, setUpcomingBookings] = useState();
  const [quickBookings, setQuickBookings] = useState();
  const employeeData = useSelector(selectEmployeeData);
  const [tips, setTips] = useState();
  const history = useHistory();

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await getDashboardFacilityData();
      if (apiResponse.response?.status === true) {
        const data = apiResponse.response?.data || {};
        const facilitiesArray = Object.values(data).flat();
        setFacilities(facilitiesArray);
        const dropdownData = facilitiesArray.map((facility) => ({
          label: facility.title,
          value: facility.facility_id,
          link: facility.link,
          address: facility.address,
          spacesCount: facility.spacesCount,
        }));
        setFacilitiesDropDownData(dropdownData);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const callUpcomingBookingApi = useCallback(async () => {
    try {
      const response = await getUpcomingBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setUpcomingBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const callQuickBookingApi = useCallback(async () => {
    try {
      const response = await getQuickBookingApi(employeeData.employee_id);
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setQuickBookings(data);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [employeeData.employee_id]);

  const callTipsApi = useCallback(async () => {
    try {
      const response = await getTipsApi();
      if (Object.keys(response).length > 0) {
        if (response.response.statusCode === 200) {
          if (response.response.data) {
            const data = response.response.data;
            setTips(data.tips);
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const renderScreen = useCallback(async () => {
    callFacilitiesApi();
    callUpcomingBookingApi();
    callQuickBookingApi();
    callTipsApi();
  }, [callFacilitiesApi, callQuickBookingApi, callUpcomingBookingApi, callTipsApi]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);
  return (
    <div className="d-flex flex-row">
      <div className="w-75">
        {vehicleHomeConfig && vehicleHomeConfig.banners.length > 0 && (
          <Carousel
            activeIndex={currentActiveSlideIndex}
            className="pb-1 seat-booking-carousel"
            nextIcon={
              vehicleHomeConfig.banners.length > 1 && (
                <span aria-hidden="true" className="carousel-control-next-icon" />
              )
            }
            prevIcon={
              vehicleHomeConfig.banners.length > 1 && (
                <span aria-hidden="true" className="carousel-control-prev-icon" />
              )
            }
            onSelect={(selectedIndex) => setCurrentActiveSlideIndex(selectedIndex)}>
            {vehicleHomeConfig.banners.map((imageItem, index) => {
              return (
                <Carousel.Item key={`${index}`}>
                  <div className="d-flex justify-content-center">
                    <img
                      className="estore-carousel-image seat-booking-carousel-border"
                      src={imageItem.base_url + imageItem.filename}
                    />
                  </div>
                </Carousel.Item>
              );
            })}
          </Carousel>
        )}

        {upcomingBookings && upcomingBookings.length > 0 && (
          <div>
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">UPCOMING BOOKINGS</div>
            </div>
            {upcomingBookings.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <UpcomingItem item={item} index={index} />
                </div>
              );
            })}
          </div>
        )}
        {quickBookings && quickBookings.length > 0 && (
          <div>
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">QUICK BOOKINGS</div>
            </div>
            <div className="d-flex flex-row w-100">
              {quickBookings.map((item, index) => {
                return (
                  <div className="" key={`${index}`}>
                    <QuickBookingItem item={item} index={index} />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <div className="d-flex w-50 justify-content-center">
          <div className="w-100">
            <CustomButton
              buttonStyle="estore-add-to-cart-button mt-3 mb-5"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.BOOK_A_PARKING_SCREEN);
              }}>
              BOOK NOW
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="seat-booking-right-section-container ml-5">
        {facilitiesDropDownData && facilitiesDropDownData.length > 0 && (
          <>
            <div className="estore-home-tab-products-header">
              <div className="estore-product-type-title">OFFICE LOCATIONS</div>
            </div>
            <div className="d-flex flex-row justify-content-between w-100">
              <div className="w-100">
                <>
                  {facilitiesDropDownData?.map((item, index) => {
                    return (
                      <div key={`${index}`} className="w-100">
                        <OfficeItem item={item} index={index} />
                      </div>
                    );
                  })}
                </>
              </div>
            </div>
          </>
        )}
        {tips && tips?.length > 0 && (
          <>
            <div className="estore-home-tab-products-header my-3">
              <div className="estore-product-type-title">HELPFUL TIPS</div>
            </div>
            {tips.map((item, index) => {
              return (
                <div key={`${index}`}>
                  <HelpfulTipsItem item={item} index={index} />
                  {index !== tips.length - 1 && <Divider />}
                </div>
              );
            })}
          </>
        )}
      </div>
    </div>
  );
};

export default VehicleParkingHomeTab;
