import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import CommentBox from '../../../../common/ui/comment_box/CommentBox';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import {
  GET_MICROGIVE_DISCUSSIONS,
  SUBMIT_MICROGIVE_DISCUSSION_COMMENT,
} from '../../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../../state/UIState';
import { isEmptyText, showToast } from '../../../../utils/common';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import DiscussionTabItem from '../../components/DiscussionTabItem';
import { selectAccessibilityData } from '../../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../../utils/accessibility';

const VolunteerDiscussionTab = ({ initiativeId }) => {
  const dispatch = useDispatch();
  const [discussions, setDiscussions] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const accessibilityData = useSelector(selectAccessibilityData);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.VOLUNTEER_DISCUSSION_TAB_VISIT,
      initiativeId,
      ANALYTICS_ITEM_NAMES.MICROGIVE
    );
  }, [initiativeId]);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(
            GET_MICROGIVE_DISCUSSIONS + initiativeId + '/discussions',
            REQUEST_TYPE.GET,
            params
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussions((oldArray) => {
                  const newDiscussions = apiResponse.response.data.feeds.reverse();
                  const existingDiscussionIds = new Set(oldArray.map((item) => item.discussion_id));
                  const uniqueDiscussions = newDiscussions.filter((item) => {
                    if (!existingDiscussionIds.has(item.discussion_id)) {
                      existingDiscussionIds.add(item.discussion_id);
                      return true;
                    }
                    return false;
                  });

                  return [...uniqueDiscussions, ...oldArray];
                });
                setPageId(id);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_MICROGIVE_DISCUSSIONS + initiativeId + '/discussions',
            NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN
          );
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, initiativeId]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const callCommentApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const params = new URLSearchParams();
      params.append('description', commentText.trim());
      const apiResponse = await apiRequest(
        SUBMIT_MICROGIVE_DISCUSSION_COMMENT + initiativeId + '/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussions([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.VOLUNTEER_DISCUSSION_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const handleChange = (value) => {
    setCommentText(value);
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId + 1, loadMoreFlag);
  };

  return (
    <div className="main-content-container">
      <div>
        {loadMoreFlag && (
          <div className="mb-2 mt-4">
            <div className="loadMoreContainer">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                  ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                )}
                className="load-more-icon mr-2"
                onClick={onPressLoadPrevious}
              />
              <div className="loadmoreText" onClick={onPressLoadPrevious}>
                Load previous comments
              </div>
            </div>
          </div>
        )}
        {[...discussions].map((item, index) => {
          return <DiscussionTabItem key={`${index}`} item={item} />;
        })}
      </div>
      <CommentBox
        getCommentText={onPressSend}
        onChangehandler={handleChange}
        commentText={commentText}
        textValue="Send"
        showLoader={progressLoadingState.isProgressLoading}
      />
    </div>
  );
};

export default VolunteerDiscussionTab;
