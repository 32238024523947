import { combineReducers } from 'redux';
import { accessibilityDataSliceReducer } from './AccessibilityData';
import { anniversaryDataSliceReducer } from './AnniversaryData';
import { cheersDataSliceReducer } from './CheersData';
import { commentDataSliceReducer } from './CommentData';
import { dashboardDataSliceReducer } from './DashboardData';
import { employeeDataSliceReducer } from './EmployeeData';
import { masterDataSliceReducer } from './MasterData';
import { notificationDataSliceReducer } from './NotificationData';
import { menuDataSliceReducer } from './MenuData';
import { tabDataSliceReducer } from './TabData';
import { uiSliceReducer } from './UIState';
import { communityDataSliceReducer } from './CommunityData';
import { ijprDataSliceReducer } from './IjprData';
import { chatDataSliceReducer } from './ChatData';
import { profanityDataSliceReducer } from './ProfanityData';
import { travelRequestMasterDataSliceReducer } from './TravelRequestMasterData';
import { policiesDataSliceReducer } from './PoliciesData';
import { accessDataSliceReducer } from './UserAccessData';
import { storeDataSliceReducer } from './EStoreData';
import { bookingDataSliceReducer } from './SeatBookingData';

const rootReducer = (dynamicReducers) =>
  combineReducers({
    accessibilityData: accessibilityDataSliceReducer,
    masterData: masterDataSliceReducer,
    employeeData: employeeDataSliceReducer,
    ui: uiSliceReducer,
    cheersData: cheersDataSliceReducer,
    tabData: tabDataSliceReducer,
    commentData: commentDataSliceReducer,
    anniversaryData: anniversaryDataSliceReducer,
    notificationData: notificationDataSliceReducer,
    dashboardData: dashboardDataSliceReducer,
    menuData: menuDataSliceReducer,
    communityData: communityDataSliceReducer,
    ijprData: ijprDataSliceReducer,
    chatData: chatDataSliceReducer,
    profanityData: profanityDataSliceReducer,
    travelRequestMasterData: travelRequestMasterDataSliceReducer,
    policiesData: policiesDataSliceReducer,
    accessData: accessDataSliceReducer,
    storeData: storeDataSliceReducer,
    seatData: bookingDataSliceReducer,
    ...dynamicReducers,
  });

export default rootReducer;
