import React from 'react';
import { useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../../common/constants/AppConstants';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { formatDateDDMonthYYYY, getUrlWithSpecificRendition } from '../../../utils/common';

const GalleryImageView = ({ item, onPressMore, location }) => {
  const accessibilityData = useSelector(selectAccessibilityData);

  return (
    <div className="col-sm-4 mb-3">
      <div className="gallery-main">
        <img
          src={
            item.promo_image &&
            getUrlWithSpecificRendition(
              item.base_url,
              SCREEN_DENSITY_QUALIFIERS.HDPI,
              item.promo_image
            )
          }
          className="embed-responsive embed-responsive-16by9"
        />
        {item.gallery.length > 0 && (
          <div
            onClick={() => {
              let pictures = [];
              if (item.gallery && item.gallery.length > 0) {
                pictures = item.gallery.map((item) => {
                  return { ['picture_path']: item.image_path, ['picture_caption']: item.caption };
                });
              }
              onPressMore(pictures, item.base_url, item.title, item.id);
            }}
            className="gallery-bg-view">
            <div className="gallery-count-left-text">
              {item.gallery.length === 1
                ? `${item.gallery.length} Photo`
                : `${item.gallery.length} Photos`}
            </div>
          </div>
        )}
      </div>
      {item.start_date && (
        <div className="gallery-flex mt-1">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_CALENDER,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="gallery-calender-style"
          />
          <div className="gallery-event-date">{formatDateDDMonthYYYY(item.start_date)}</div>
        </div>
      )}
      <div className="gallery-title">{item.title}</div>
      {!!location && (
        <div className="gallery-flex mt-1">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.GALLERY_LOCATION,
              ACCESSIBILITY_IDENTIFIERS.GALLERY
            )}
            className="gallery-calender-style"
          />
          <div className="gallery-location">{location}</div>
        </div>
      )}
    </div>
  );
};
export default GalleryImageView;
