import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { trackScreen } from '../../../utils/FirebaseAnalyticsUtils';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import CommonGalleryImageComponent from '../../components/CommonGalleryImageComponent';
import { saveProgressLoadingState } from '../../../state/UIState';
import { apiRequest } from '../../../services/Service';
import { GALLERIES } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../ui/load_more_btn/LoadMoreBtn';

const GalleryFullScreen = ({ history }) => {
  const { title, data, baseUrl, id, type, apiUrl, featureParams } = history.location.state;
  const [isOverlayOpen, setOverlayOpen] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [selectedImage, setSelectedImage] = useState();
  const dispatch = useDispatch();
  const [gallery, setGallery] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(true);

  const openOverlay = (item) => {
    setSelectedImage(item);
    setOverlayOpen(true);
  };

  const closeOverlay = () => {
    setOverlayOpen(false);
    setSelectedImage();
  };

  const callGalleryDetailApi = useCallback(
    async (pageId = 1) => {
      try {
        if (pageId === 1) {
          setGallery([]);
        }
        const params = {
          page_id: pageId,
          type: type ? type : null,
        };
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = apiUrl
          ? await apiRequest(
              apiUrl,
              REQUEST_TYPE.GET,
              featureParams ? { ...params, ...featureParams } : params
            )
          : await apiRequest(
              GALLERIES + '/' + id,
              REQUEST_TYPE.GET,
              featureParams ? { ...params, ...featureParams } : params
            );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        const data =
          apiResponse.response.data.details?.pictures?.gallery ||
          apiResponse.response.data.details?.gallery ||
          apiResponse.response.data.details?.pictures ||
          apiResponse.response.data.event?.gallary?.pictures ||
          apiResponse.response.data.contest?.gallary?.pictures ||
          apiResponse.response.data.gallary?.pictures;

        if (data.length === 0) {
          setLoadMoreFlag(false);
        }
        if (pageId === 1) {
          setGallery(data);
        } else {
          setGallery((oldArray) => [...oldArray, ...data]);
        }
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressing: false }));
        console.error(err);
      }
    },
    [apiUrl, dispatch, featureParams, id, type]
  );

  useEffect(() => {
    callGalleryDetailApi();
  }, [callGalleryDetailApi]);

  const downloadImage = async (imageSrc, imageName = 'downloaded-image') => {
    try {
      const response = await fetch(imageSrc);
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = blobUrl;
      link.download = imageName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };

  useEffect(() => {
    trackScreen(
      ANALYTICS_SCREEN_NAMES.ML35_PICTURES_SCREEN,
      NAVIGATION_ROUTES.ML35_PICTURES_SCREEN
    );
  }, []);

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_IMAGES_TO_SHOW} />;
  };

  const loadMore = () => {
    callGalleryDetailApi(pageNo + 1);
    setPageNo(pageNo + 1);
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.GALLERY} />
      <BackButton backTitle={title ?? 'BACK'} />
      <div>
        {data && data?.length === 0 ? (
          emptyListView()
        ) : (
          <>
            {isOverlayOpen && selectedImage && (
              <div className="ml-35-image-overlay">
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_CLOSE,
                    ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                  )}
                  className="ml-35-image-close-button"
                  onClick={closeOverlay}
                />
                <img
                  className="ml-35-fullscreen-image"
                  src={
                    selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path
                  }
                />
                <img
                  src={getFeatureComponentUrl(
                    accessibilityData,
                    ACCESSIBILITY_FEATURE_COMPONENTS.ANNIVERSARY_IMAGE_DOWLOAD,
                    ACCESSIBILITY_IDENTIFIERS.ANNIVERSARY_DAY
                  )}
                  className="ml-35-image-download-icon common-cursor-pointer"
                  onClick={() =>
                    downloadImage(
                      selectedImage.picture.base_url + 'xxhdpi/' + selectedImage.picture.image_path,
                      `Celebration-Image`
                    )
                  }
                />
              </div>
            )}
            <div className={`ml-35-images-wrapper mb-2`}>
              {gallery.map((item, index) => (
                <CommonGalleryImageComponent
                  key={index}
                  item={item?.picture_path || item?.image_path || item}
                  index={index}
                  baseUrl={baseUrl}
                  onPress={() => openOverlay(item)}
                />
              ))}
            </div>
            <div className="mb-4" />
          </>
        )}
      </div>
      {loadMoreFlag && (
        <div className="mb-2">
          <LoadMoreBtn onClick={loadMore} />
        </div>
      )}
    </div>
  );
};

export default GalleryFullScreen;
