import React, { useEffect, useState, useCallback, useRef } from 'react';
import { getEmployeeData } from '../../utils/ReduxState';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../state/UIState';
import { apiRequest, handleError } from '../../services/Service';
import {
  ANALYTICS_SCREEN_NAMES,
  ERROR_MESSAGES,
  FILTER_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SCREEN_TITLE,
} from '../../common/constants/AppConstants';
import { GET_UPCOMING_HOLIDAYS, HOLIDAY_FILTERS } from '../../services/ApiUrls';
import { formatDateDDMonth, sortArrayOfObjects } from '../../utils/common';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import Divider from '../../common/ui/divider/Divider';
import { selectGeographies, selectLocations } from '../../state/MasterData';
import DefaultContentView from '../../common/ui/default_content_view/DefaultContentView';
import { trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import HolidayFilter from '../../common/ui/filter/HolidayFilter';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const HolidayScreen = () => {
  const [error, setError] = useState(false);
  const [holidaysData, setHolidaysData] = useState([]);
  const [employeeData, setEmployeeData] = useState({});
  const [employeeGeo, setEmployeeGeo] = useState({});
  const [employeeLocation, setEmployeeLocation] = useState({});
  const dispatch = useDispatch();
  const locationData = sortArrayOfObjects(useSelector(selectLocations), 'title');
  const geographiesData = sortArrayOfObjects(useSelector(selectGeographies), 'title');
  const [showEmptyView, setShowEmptyView] = useState(false);
  const [isUpcoming, setIsUpcoming] = useState();
  const [holidayGeographies, setHolidayGeographies] = useState();
  const [holidayLocations, setHolidayLocations] = useState();
  const [holidayYears, setHolidayYears] = useState();
  const [isClear, setIsClear] = useState(true);
  const history = useHistory();

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.HOLIDAY_CALENDAR, NAVIGATION_ROUTES.HOLIDAY_SCREEN);
  }, []);

  useEffect(() => {
    setEmployeeData(() => getEmployeeData());
    setEmployeeLocation(employeeData?.location);
    setEmployeeGeo(employeeData?.geography);
  }, [employeeData?.geography, employeeData?.location]);

  const geographyId = useRef();
  const locationId = useRef();
  const holidayYearId = useRef();

  const yearMatch = holidayYears?.find((year) => year.title == holidayYearId.current) ?? '';

  const locationMatch =
    locationData?.find((location) => location.location_id == locationId.current) ?? '';

  const filteredGeographyId = locationMatch ? locationMatch.geography_id : null;

  const matchedLocationTitle = locationMatch ? locationMatch.title : null;
  const matchedYearTitle = yearMatch ? yearMatch.title : null;

  const matchedGeoTitle =
    geographiesData?.find((geography) => geography.geography_id == filteredGeographyId)?.title ||
    null;

  const callHolidayFilterApi = useCallback(async () => {
    if (employeeData && employeeGeo && Object.keys(employeeData).length > 0) {
      try {
        setError(false);
        dispatch(
          saveProgressLoadingState({
            isProgressLoading: true,
          })
        );

        const apiResponse = await apiRequest(HOLIDAY_FILTERS, REQUEST_TYPE.GET);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            if (apiResponse.response.data) {
              const data = apiResponse?.response?.data ?? [];
              if (data) {
                setHolidayGeographies(data?.geographies);
                setHolidayLocations(data?.locations);
                setHolidayYears(data?.years);
              }
            }
          }
        }
        dispatch(resetLoaderState());
      } catch (err) {
        handleError(err, {}, HOLIDAY_FILTERS, NAVIGATION_ROUTES.HOLIDAY_SCREEN);
        dispatch(resetLoaderState());
        setError(true);
      }
    }
  }, [dispatch, employeeData, employeeGeo]);

  const callUpcomingHolidayApi = useCallback(
    async (upcoming = isUpcoming) => {
      if (employeeGeo && Object.keys(employeeGeo).length > 0) {
        const get_params = {
          geography_id: geographyId.current ? geographyId.current : employeeGeo?.geography_id,
          location_id: locationId.current ? locationId.current : employeeLocation?.location_id,
          year: holidayYearId.current ? holidayYearId.current : '',
          upcoming: upcoming,
        };
        try {
          setError(false);
          dispatch(
            saveProgressLoadingState({
              isProgressLoading: true,
            })
          );
          setShowEmptyView(false);

          const apiResponse = await apiRequest(GET_UPCOMING_HOLIDAYS, REQUEST_TYPE.GET, get_params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse?.response?.data ?? [];
                if (data?.length === 0) {
                  setShowEmptyView(true);
                }
                if (data) {
                  setHolidaysData(data);
                }
              }
            }
          } else {
            setShowEmptyView(true);
          }
          dispatch(resetLoaderState());
        } catch (err) {
          handleError(err, get_params, GET_UPCOMING_HOLIDAYS, NAVIGATION_ROUTES.HOLIDAY_SCREEN);
          setShowEmptyView(true);
          dispatch(resetLoaderState());
          setError(true);
        }
      }
    },
    [dispatch, employeeGeo, employeeLocation?.location_id, isUpcoming]
  );

  const renderScreen = useCallback(async () => {
    if (isClear === true) {
      callUpcomingHolidayApi(1);
      callHolidayFilterApi();
    } else {
      callUpcomingHolidayApi(0);
      callHolidayFilterApi();
    }
  }, [callHolidayFilterApi, callUpcomingHolidayApi, isClear]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const onClear = useCallback(
    (selectedFilters) => {
      geographyId.current = selectedFilters.geography_id
        ? selectedFilters.geography_id.toString().replace(/[[\]']+/g, '')
        : '';

      locationId.current = selectedFilters.location_id
        ? selectedFilters.location_id.toString().replace(/[[\]']+/g, '')
        : '';

      holidayYearId.current = selectedFilters.key
        ? selectedFilters.key.toString().replace(/[[\]']+/g, '')
        : '';
      setHolidaysData([]);
      callUpcomingHolidayApi(1);
    },
    [callUpcomingHolidayApi]
  );

  const filterData = useCallback(
    (selectedFilters) => {
      geographyId.current = selectedFilters.geography_id
        ? selectedFilters.geography_id.toString().replace(/[[\]']+/g, '')
        : '';

      locationId.current = selectedFilters.location_id
        ? selectedFilters.location_id.toString().replace(/[[\]']+/g, '')
        : '';

      holidayYearId.current = selectedFilters.key
        ? selectedFilters.key.toString().replace(/[[\]']+/g, '')
        : '';
      setHolidaysData([]);
      callUpcomingHolidayApi(0);
    },
    [callUpcomingHolidayApi]
  );

  const renderUpcomingHolidays = (itemData, isLastItem) => {
    return (
      <>
        <div className="holiday-text-container d-flex flex-column pl-3 w-100 justify-content-center">
          <div className="d-flex flex-row ml-2">
            <div className="holiday-card mr-3 d-flex justify-content-center align-items-center flex-column">
              <h5 className="mt-3 mb-n1">
                {formatDateDDMonth(itemData?.holidays?.holiday_date).slice(0, 2)}
              </h5>
              <h6 className="mb-3">
                {formatDateDDMonth(itemData?.holidays?.holiday_date).slice(3, 6)},{' '}
                {itemData?.holidays?.holiday_date.slice(2, 4)}
              </h6>
            </div>
            <div className="d-flex flex-column justify-content-center">
              <div className="home-horizontal-icon-small-title">
                {itemData?.holidays?.holiday_title}
              </div>
              <div className="dynamic-columns-holiday-day-text">
                {itemData?.holidays?.holiday_day}
              </div>
            </div>
          </div>
          {!isLastItem && <Divider style="mt-3 mb-3 w-50" />}
        </div>
      </>
    );
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.NO_HOLIDAYS_FOUND} />;
  };

  return (
    <div className="container">
      <SectionHeader title={SCREEN_TITLE.HOLIDAY_CALENDAR} />
      <div className="mt-4 justify-content-between">
        <div className="mb-3 mt-n2">
          <HolidayFilter
            topFilter={FILTER_IDENTIFIERS.GEOGRAPHIES}
            geographies={true}
            locations={true}
            fromRoute={NAVIGATION_ROUTES.HOLIDAY_SCREEN}
            onClick={filterData}
            onClear={onClear}
            holidayYear={true}
            holidayGeographies={holidayGeographies}
            holidayLocations={holidayLocations}
            holidayYearList={holidayYears}
            setIsClear={setIsClear}
          />
        </div>
        <div className="employee-location-text-item mb-3 ml-3">
          {matchedLocationTitle
            ? matchedLocationTitle
            : employeeLocation?.title
            ? employeeLocation?.title
            : ''}
          , {matchedGeoTitle ? matchedGeoTitle : employeeGeo?.title ? employeeGeo?.title : ''}
          {matchedYearTitle ? `, ${matchedYearTitle}` : ''}
        </div>
      </div>
      <Divider style={'mb-3 w-100'} />

      {showEmptyView && holidaysData?.length === 0 && emptyListView()}

      {holidaysData?.length > 0 && (
        <div className="w-100 mb-3">
          {holidaysData?.map((item, index) => {
            const isLastItem = index === holidaysData.length - 1;
            return (
              <div key={`${index}`} className="d-flex flex-row w-100">
                {renderUpcomingHolidays(item, isLastItem)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default HolidayScreen;
