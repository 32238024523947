import React, { createRef, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { ACCESSIBILITY_IDENTIFIERS, NAVIGATION_ROUTES } from '../common/constants/AppConstants';
import HTMLViewScreen from '../common/screens/HTMLView/HTMLViewScreen';
import AskSessionQuestionScreen from '../common/screens/SessionDetail/AskSessionQuestionScreen';
import SessionDetailScreen from '../common/screens/SessionDetail/SessionDetailScreen';
import AddContestsScreen from '../screen/anniversary/AddContestsScreen';
import AddContestsThanksScreen from '../screen/anniversary/AddContestsThanksScreen';
import AnniversaryScreen from '../screen/anniversary/AnniversaryScreen';
import ContestsDetailScreen from '../screen/anniversary/ContestsDetailScreen';
import DigitalWarriorScreen from '../screen/anniversary/DigitalWarriorScreen';
import MomentTagDetailsScreen from '../screen/anniversary/MomentTagDetailsScreen';
import PledgeScreen from '../screen/anniversary/PledgeScreen';
import PledgeThanksScreen from '../screen/anniversary/PledgeThanksScreen';
import TakeThePledgeScreen from '../screen/anniversary/TakeThePledgeScreen';
import WallpaperContentScreen from '../screen/anniversary/WallpaperContentScreen';
import WallpapersScreen from '../screen/anniversary/WallpapersScreen';
import WatchLiveScreen from '../screen/anniversary/WatchLiveScreen';
import BreakoutsGalleryScreen from '../screen/breakouts/BreakoutsGalleryScreen';
import BreakoutsInfoDetailScreen from '../screen/breakouts/BreakoutsInfoDetailScreen';
import BreakoutsScreen from '../screen/breakouts/BreakoutsScreen';
import BreakoutsTabScreen from '../screen/breakouts/BreakoutsTabScreen';
import GalleryFullImageScreen from '../screen/breakouts/GalleryFullImageScreen';
import DirectoryScreen from '../screen/directory/DirectoryScreen';
import AskQuestionScreen from '../screen/ceochat/AskQuestionScreen';
import CeoChatCommentScreen from '../screen/ceochat/CeoChatCommentScreen';
import CeoChatContentScreen from '../screen/ceochat/CeoChatContentScreen';
import CeoChatScreen from '../screen/ceochat/CeoChatScreen';
import HomeScreen from '../screen/home/HomeScreen';
import DonationEventByDateScreen from '../screen/microgive/DonationEventByDateScreen';
import DonationSearchScreen from '../screen/microgive/DonationSearchScreen';
import DonationTabsScreen from '../screen/microgive/DonationTabsScreen';
import MicrogiveGalleryTagsDetailScreen from '../screen/microgive/MicrogiveGalleryTagsDetailScreen';
import MicrogiveScreen from '../screen/microgive/MicrogiveScreen';
import ProjectDetailByDateScreen from '../screen/microgive/ProjectDetailByDateScreen';
import ProjectDetailScreen from '../screen/microgive/ProjectDetailScreen';
import ProjectDetailSearchScreen from '../screen/microgive/ProjectDetailSearchScreen';
import ProjectsSearchScreen from '../screen/microgive/ProjectsSearchScreen';
import ProjectTabsScreen from '../screen/microgive/ProjectTabsScreen';
import QueryScreen from '../screen/microgive/QueryScreen';
import SelectDateScreen from '../screen/microgive/SelectDateScreen';
import SelectDonationScreen from '../screen/microgive/SelectDonationScreen';
import UpdatesScreen from '../screen/microgive/UpdatesScreen';
import VolunteerParticipatesScreen from '../screen/microgive/VolunteerParticipatesScreen';
import VolunteerTabsScreen from '../screen/microgive/VolunteerTabsScreen';
import PoshScreen from '../screen/posh/PoshScreen';
import ForwordScreen from '../screen/som/ForeWordScreen';
import SomEmployeeDetailsScreen from '../screen/som/SomEmployeeDetailsScreen';
import SOMGalleryScreen from '../screen/som/SOMGalleryScreen';
import SomMainScreen from '../screen/som/SomMainScreen';
import SomTabScreen from '../screen/som/SomTabScreen';
import SomTagPeopleScreen from '../screen/som/TagPeopleScreen';
import CeoReplyScreen from '../screen/ceochat/CeoReplyScreen';
import VolunteeringLogScreen from '../screen/microgive/VolunteeringLogScreen';
import MyDonationsScreen from '../screen/microgive/MyDonationsScreen';
import TagPeopleScreen from '../screen/microgive/TagPeopleScreen';
import CheersScreen from '../screen/cheers/CheersScreen';
import MyPointsScreen from '../screen/cheers/MyPointsScreen';
import NominationsScreen from '../screen/cheers/NominationsScreen';
import SelfScreen from '../screen/cheers/SelfScreen';
import PeerScreen from '../screen/cheers/PeerScreen';
import ViewEligibleAwardScreen from '../screen/cheers/ViewEligibleAwardScreen';
import MyManagerScreen from '../screen/cheers/MyManagerScreen';
import ViewPeerAwardScreen from '../screen/cheers/ViewPeerAwardScreen';
import MyTeamScreen from '../screen/cheers/MyTeamScreen';
import ViewMyTeamAwardScreen from '../screen/cheers/ViewMyTeamAwardScreen';
import RedeemPointsScreen from '../screen/cheers/RedeemPointsScreen';
import DonatePointsScreen from '../screen/cheers/DonatePointsScreen';
import ViewNominationsScreen from '../screen/cheers/ViewNominationsScreen';
import PointsRedeemedScreen from '../screen/cheers/PointsRedeemedScreen';
import RewardDetailScreen from '../screen/cheers/RewardDetailScreen';
import TaggedPeopleScreen from '../screen/cheers/TaggedPeopleScreen';
import WishesScreen from '../common/ui/profile_detail/WishesScreen';
import WomensDayMainScreen from '../screen/womens_day/WomensDayMainScreen';
import WomensDayGalleryScreen from '../screen/womens_day/WomensDayGalleryScreen';
import WomensDayGalleryfullImageScreen from '../screen/womens_day/WomensDayGalleryFullImageScreen';
import CommentScreen from '../common/screens/comment/CommentScreen';
import CeoChatAddCommentScreen from '../screen/ceochat/CeoChatAddCommentScreen';
import BusinessDashboard from '../screen/business_dashboard/BusinessDashboardScreen';
import WomensDayWatchVideoScreen from '../screen/womens_day/WomensDayWatchVideoScreen';
import MyAssetScreen from '../screen/profile/MyAssetScreen';
import MyAssetDetailScreen from '../screen/profile/MyAssetDetailScreen';
import MyProfileDetailScreen from '../screen/profile/MyProfileDetailScreen';
import MyProfileDetailEditScreen from '../screen/profile/MyProfileDetailEditScreen';
import NewsMainScreen from '../screen/news/NewsMainScreen';
import NewsSearchScreen from '../screen/news/NewsSearchScreen';
import NewsContentScreen from '../screen/news/NewsContentScreen';
import PandemicSupportTabScreen from '../screen/pandemic_support/PandemicSupportTabScreen';
import FullScreenVideoScreen from '../common/screens/FullScreenVideo/FullScreenVideoScreen';
import BroadcastMainScreen from '../screen/broadcasts/BroadcastMainScreen';
import MLTVMainScreen from '../screen/MLTV/MltvMainScreen';
import BroadcastSearchScreen from '../screen/broadcasts/BroadcastSearchScreen';
import BroadcastContentScreen from '../screen/broadcasts/BroadcastContentScreen';
import LikedPeopleScreen from '../common/screens/LikedPeople/LikedPeopleScreen';
import PoliciesScreen from '../screen/policies/PoliciesScreen';
import PolicySearchScreen from '../screen/policies/PolicySearchScreen';
import PolicyDetailScreen from '../screen/policies/PolicyDetailScreen';
import DearEmployerMainScreen from '../screen/dear_employer/DearEmployerMainScreen';
import DearEmployerContentScreen from '../screen/dear_employer/DearEmployerContentScreen';
import EventMainScreen from '../screen/events/EventMainScreen';
import EventContentScreen from '../screen/events/EventContentScreen';
import EventDateScreen from '../screen/events/EventDateScreen';
import EventGalleryScreen from '../screen/events/EventGalleryScreen';
import CelebrationsScreen from '../screen/celebrations/CelebrationsScreen';
import PollsAndSurveysMainScreen from '../screen/pollsandsurveys/PollsAndSurveysMainScreen';
import PollViewResultScreen from '../screen/pollsandsurveys/PollViewResultScreen';
import SurveyParticipateScreen from '../screen/pollsandsurveys/SurveyParticipateScreen';
import SurveySuccessScreen from '../screen/pollsandsurveys/SurveySuccessScreen';
import DownloadAppScreen from '../screen/promo/DownloadAppScreen';
import GcdoMainScreen from '../screen/gcdo/GcdoMainScreen';
import GcdoContentScreen from '../screen/gcdo/GcdoContentScreen';
import GcdoSearchScreen from '../screen/gcdo/GcdoSearchScreen';
import ContestMainScreen from '../screen/contests/ContestMainScreen';
import ContestContentScreen from '../screen/contests/ContestContentScreen';
import ContestGalleryScreen from '../screen/contests/ContestGalleryScreen';
import ContestDateScreen from '../screen/contests/ContestDateScreen';
import OurCustomerMainScreen from '../screen/ourcustomers/OurCustomerMainScreen';
import OurCustomerContentScreen from '../screen/ourcustomers/OurCustomerContentScreen';
import GalleryMainScreen from '../screen/gallery/GalleryMainScreen';
import GallerySearchScreen from '../screen/gallery/GallerySearchScreen';
import GalleryScreen from '../common/screens/gallery/GalleryScreen';
import FacilitiesMainScreen from '../screen/facilities/FacilitiesMainScreen';
import FacilityContentScreen from '../screen/facilities/FacilityContentScreen';
import NewJoineesLowerLevelsScreen from '../screen/new_joinees/NewJoineesLowerLevelsScreen';
import CommunitiesMainScreen from '../screen/communities/CommunitiesMainScreen';
import CommunityDetailScreen from '../screen/communities/CommunityDetailScreen';
import ViewMembersScreen from '../screen/communities/ViewMembersScreen';
import ViewCommunityPosts from '../screen/communities/ViewCommunityPosts';
import CommunityTagPeopleScreen from '../screen/communities/CommunityTagPeopleScreen';
import EcardMainScreen from '../screen/ecards/EcardMainScreen';
import EcardContentScreen from '../screen/ecards/EcardContentScreen';
import NotificationListingScreen from '../screen/notiifcation/NotificationListingScreen';
import TheWallMainScreen from '../screen/wall/TheWallMainScreen';
import TheWallTaggedPeopleScreen from '../screen/wall/TheWallTaggedPeopleScreen';
import PdfViewerScreen from '../common/screens/PdfViewer/PdfViewerScreen';
import ScrollToTop from '../common/ui/scroll_to_top/ScrollToTop';
import PageNotFoundScreen from '../common/screens/pagenotfound/PageNotFoundScreen';
import TermsConditionsScreen from '../screen/intro/TermsConditionsScreen';
import PollsNotificationScreen from '../screen/pollsandsurveys/PollsNotificationScreen';
import SurveysNotificationScreen from '../screen/pollsandsurveys/SurveysNotificationScreen';
import GlobalSearchMainScreen from '../screen/global_search/GlobalSearchMainScreen';
import IjprScreen from '../screen/ijpr/IjprScreen';
import { ErrorBoundary } from 'react-error-boundary';
import MicroKnowScreen from '../screen/microknow/MicroKnowScreen';
import MicroKnowSearchScreen from '../screen/microknow/MicroKnowSearchScreen';
import PageNotFound from '../common/ui/page_not_found/PageNotFound';
import ProjectDonationTabsScreen from '../screen/microgive/ProjectDonationTabsScreen';
import AnniversaryDayScreen from '../screen/anniversary/AnniversaryDayScreen';
import AnniversaryGalleryFullImageScreen from '../screen/anniversary/AnniversaryGalleryFullImageScreen';
import DIScreen from '../screen/di/DIScreen';
import DIContentScreen from '../screen/di/DIContentScreen';
import DISearchScreen from '../screen/di/DISearchScreen';
import SalesIncentiveScreen from '../screen/sales_incentive/SalesIncentiveScreen';
import EmployeeProfileDetailScreen from '../screen/profile/EmployeeProfileDetailScreen';
import OrgStructureScreen from '../screen/profile/OrgStructureScreen';
import PostDetailScreen from '../screen/communities/PostDetailScreen';
import WritePostScreen from '../screen/communities/WritePostScreen';
import DIEventDateScreen from '../screen/di/DIEventDateScreen';
import IjprSearchResultScreen from '../screen/ijpr/IjprSearchResultScreen';
import IjprDetailScreen from '../screen/ijpr/IjprDetailScreen';
import IjpReferSearchResultScreen from '../screen/ijpr/IjpReferSearchResultScreen';
import IjpReferDetailScreen from '../screen/ijpr/IjpReferDetailScreen';
import MicrobizScreen from '../screen/microbiz/MicrobizScreen';
import MicrobizContentScreen from '../screen/microbiz/MicrobizContentScreen';
import MicrobizSearchScreen from '../screen/microbiz/MicrobizSearchScreen';
import EditPostScreen from '../screen/communities/EditPostScreen';
import BtoScreen from '../screen/bto/BtoScreen';
import BtoContentScreen from '../screen/bto/BtoContentScreen';
import BtoSearchScreen from '../screen/bto/BtoSearchScreen';
import TheWallPostDetailScreen from '../screen/wall/TheWallPostDetailScreen';
import PodcastScreen from '../screen/podcast/PodcastScreen';
import PodcastContentScreen from '../screen/podcast/PodcastContentScreen';
import PodcastEpisodeDetailScreen from '../screen/podcast/PodcastEpisodeDetailScreen';
import ChatScreen from '../screen/chat/ChatScreen';
import WallpaperScreen from '../screen/wallpaper/WallpaperScreen';
import TravelRequestScreen from '../screen/travel_request/TravelRequestScreen';
import TravelRequestReviewScreen from '../screen/travel_request/TravelRequestReviewScreen';
import IjprBookmarkScreen from '../screen/ijpr/IjprBookmarkScreen';
import PolicyTabsScreen from '../screen/policies/PolicyTabsScreen';
import RaiseConcernScreen from '../screen/posh/RaiseConcernScreen';
import MLMondayScreen from '../screen/micromonday/MLMondayScreen';
import MLMondayContentScreen from '../screen/micromonday/MLMondayContentScreen';
import AnniversaryArticleScreen from '../screen/anniversary/AnniversaryArticleScreen';
import DearEmployerSearchScreen from '../screen/dear_employer/DearEmployerSearchScreen';
import { useSelector } from 'react-redux';
import { selectIsCheersAvailableData, selectMenuData } from '../state/MenuData';
import AllWishesScreen from '../screen/profile/AllWishesScreen';
import CelebrationWishesScreen from '../screen/profile/CelebrationWishesScreen';
import MiHRMainScreen from '../screen/mi_hr/MiHRMainScreen';
import MiHRContentScreen from '../screen/mi_hr/MiHRContentScreen';
import MiHRSearchScreen from '../screen/mi_hr/MiHRSearchScreen';
import ProfileCheersListingScreen from '../screen/profile/ProfileCheersListingScreen';
import NewJoineeSessionDetailScreen from '../common/screens/SessionDetail/NewJoineeSessionDetailScreen';
import WomensDayListingScreen from '../screen/womens_day/WomensDayListingScreen';
import DownloadAppPopupWeb from '../common/ui/download_app_popup/DownloadAppPopupWeb';
import EsgMainScreen from '../screen/esg/EsgMainScreen';
import MlfMainScreen from '../screen/mlf/MlfMainScreen';
import EsgSearchScreen from '../screen/esg/EsgSearchScreen';
import EsgContentScreen from '../screen/esg/EsgContentScreen';
import MlfSearchScreen from '../screen/mlf/MlfSearchScreen';
import MlfContentScreen from '../screen/mlf/MlfContentScreen';
import AllInductionSessionTab from '../screen/new_joinees/tabs/HigherLevelsTabs/AllInductionSessionTab';
import SessionDiscussionScreen from '../common/screens/SessionDetail/SessionDiscussionScreen';
import MicrolandValuesMainScreen from '../screen/microland_values/MicrolandValuesMainScreen';
import OurCustomerAllMainScreen from '../screen/ourcustomers/OurCustomerAllMainScreen';
import TheWallNewPostScreen from '../screen/wall/TheWallNewPostScreen';
import OurCustomerSearchScreen from '../screen/ourcustomers/tabs/OurCustomerSearchScreen';
import OurCustomerKnowSearchScreen from '../screen/ourcustomers/tabs/OurCustomerKnowSearchScreen';
import CommunitiesMainScreenPostScreen from '../screen/communities/CommunitiesMainScreenPostScreen';
import VariablePayScreen from '../screen/variable_pay/VariablePayScreen';
import EmergencyPlaybookMainScreen from '../screen/emergency_playbook/EmergencyPlaybookMainScreen';
import EmergencyPlaybookContentScreen from '../screen/emergency_playbook/EmergencyPlaybookContentScreen';
import HolidayScreen from '../screen/holiday/HolidayScreen';
import NotificationSearchScreen from '../screen/notiifcation/NotificationSearchScreen';
import AskMlScreen from '../screen/ask_ml/AskMlScreen';
import AskMlSearchResultsScreen from '../screen/ask_ml/AskMlSearchResultsScreen';
import AskOrgSearchResultDetailScreen from '../screen/ask_ml/AskOrgSearchResultDetailScreen';
import MiAuctionMainScreen from '../screen/mi_auction/MiAuctionMainScreen';
import MiAuctionDetailsScreen from '../screen/mi_auction/MiAuctionDetailsScreen';
import MiAuctionTCScreen from '../screen/mi_auction/MiAuctionTCScreen';
import FeedbackScreen from '../screen/feedback/FeedbackScreen';
import MicrogiveFeedbackScreen from '../screen/feedback/MicrogiveFeedback';
import CheersDetailScreen from '../screen/cheers/CheersDetailScreen';
import ContestsSearchScreen from '../screen/contests/ContestsSearchScreen';
import EventsSearchScreen from '../screen/events/EventsSearchScreen';
import PodcastsSearchScreen from '../screen/podcast/PodcastsSearchScreen';
import MicroLawScreen from '../screen/micro_law/MicroLawScreen';
import MicroLawContentDetailScreen from '../screen/micro_law/MicroLawContentScreen';
import MichildcareMainScreen from '../screen/michildcare/MichildcareMainScreen';
import MicrostoreMainScreen from '../screen/e_store/MicrostoreMainScreen';
import MiStorePolicyScreen from '../screen/e_store/MiStorePolicyScreen';
import MiStoreAllProductsScreen from '../screen/e_store/MiStoreAllProductsScreen';
import MiStoreProductDetailsScreen from '../screen/e_store/MiStoreProductDetailsScreen';
import MiStoreCartScreen from '../screen/e_store/MiStoreCartScreen';
import DeliveryEstimateScreen from '../screen/e_store/DeliveryEstimateScreen';
import OrderTermsAndConditions from '../screen/e_store/OrderTermsAndConditions';
import OrderSuccessScreen from '../screen/e_store/OrderSuccessScreen';
import AddressListScreen from '../screen/e_store/AddressListScreen';
import EStoreAddAddressScreen from '../screen/e_store/AddAddressScreen';
import EStoreEditAddressScreen from '../screen/e_store/EStoreEditAddressScreen';
import SelectAddressScreen from '../screen/e_store/SelectAddressScreen';
import OrderHistoryListScreen from '../screen/e_store/OrderHistoryListScreen';
import ShipmentDetailsScreen from '../screen/e_store/ShipmentDetailsScreen';
import OrderDetailsScreen from '../screen/e_store/OrderDetailsScreen';
import InvoiceScreen from '../screen/e_store/InvoiceScreen';
import MLMondaySearchScreen from '../screen/micromonday/MLMondaySearchScreen';
import { uId, updateOnlineOfflineStatus } from '../utils/ChatHandler';
import { selectEmployeeId } from '../state/EmployeeData';
import OurCustomersResearchersDetails from '../screen/ourcustomers/OurCustomersResearchersDetails';
import ChildCareContentScreen from '../screen/michildcare/ChildCareCntentScreen';
import ML35PicturesScreen from '../screen/anniversary/components/ML35PicturesScreen';
import CommentScreenWall from '../common/screens/commentWall/CommentScreen';
import MLTVContentScreen from '../screen/MLTV/MltvContentScreen';
import NeoSessionDetailMainScreen from '../screen/new_joinees/tabs/HigherLevelsTabs/NeoSessionDetailMainScreen';
import MlDayGalleryFullScreen from '../screen/gallery/components/MlDayGalleryFullScreen';
import MLTVSearchScreen from '../screen/MLTV/MltvSearchScreen';
import GalleryFullScreen from '../common/screens/gallery/GalleryFullScreen';
import SeatBookingMainScreen from '../screen/seat_booking/SeatBookingMainScreen';
import VehicleParkingMainScreen from '../screen/vehicle_parking/VehicleParkingMainScreen';
import BookAParkingTab from '../screen/vehicle_parking/tabs/BookAParkingTab';
import BookASpaceTab from '../screen/seat_booking/tabs/BookASpaceTab';
import BookingPreviewScreen from '../screen/vehicle_parking/BookingPreviewScreen';
import SeatBookingPreviewScreen from '../screen/seat_booking/BookingPreviewScreen';

export const historyRef = createRef();

const Navigation = () => {
  const history = useHistory();
  const location = useLocation();
  const isCheersAvailable = useSelector(selectIsCheersAvailableData);
  const menuData = useSelector(selectMenuData);
  const [allRoutes, setAllRoutes] = useState([]);
  const employeeId = useSelector(selectEmployeeId);
  historyRef.current = history;

  const Fallback = ({ error, resetErrorBoundary }) => {
    return <PageNotFound onPress={resetErrorBoundary} />;
  };

  const errorHandler = () => {};

  /**
   * Redirect user to home page if cheers is feature is not coming in menu.
   * @param {*} param
   * @returns
   */
  function CheersProtectedRoute({ component: Component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isCheersAvailable ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: NAVIGATION_ROUTES.HOME,
              }}
            />
          )
        }
      />
    );
  }

  function AvailableRoute({ component: Component, identifier, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          allRoutes.includes(identifier) ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: NAVIGATION_ROUTES.HOME,
              }}
            />
          )
        }
      />
    );
  }

  useEffect(() => {
    const allTempRoutes = [];
    Object.keys(menuData).length > 0 &&
      menuData.data.section_list.map((section) => {
        if (section.identifier !== '_section_favorites') {
          section.features.map((feature) => {
            allTempRoutes.push(feature.identifier);
          });
        }
      });
    setAllRoutes(allTempRoutes);
  }, [menuData]);

  const onFocusFunction = useCallback(() => {
    const uid = uId({ employee_id: employeeId });
    updateOnlineOfflineStatus(true, uid, () => {});
  }, [employeeId]);

  const onBlurFunction = useCallback(() => {
    const uid = uId({ employee_id: employeeId });
    updateOnlineOfflineStatus(false, uid, () => {});
  }, [employeeId]);

  useEffect(() => {
    if (employeeId) {
      onFocusFunction();
      window.addEventListener('focus', onFocusFunction);
      window.addEventListener('blur', onBlurFunction);
      return () => {
        onBlurFunction();
        window.removeEventListener('focus', onFocusFunction);
        window.removeEventListener('blur', onBlurFunction);
      };
    }
  }, [employeeId, onBlurFunction, onFocusFunction]);

  return (
    <div className="navigation-custom-container">
      <ScrollToTop />
      <ErrorBoundary
        key={location.pathname}
        FallbackComponent={Fallback}
        onReset={() => history.replace(NAVIGATION_ROUTES.HOME)}
        onError={errorHandler}>
        {allRoutes.length > 0 && (
          <Switch>
            <AvailableRoute
              identifier={'_broadcasts'}
              exact
              path={NAVIGATION_ROUTES.BROADCASTS}
              component={BroadcastMainScreen}
            />
            <AvailableRoute
              identifier={'_mltv'}
              exact
              path={NAVIGATION_ROUTES.MLTV}
              component={MLTVMainScreen}
            />
            <AvailableRoute
              identifier={'_mltv'}
              exact
              path={`${NAVIGATION_ROUTES.MLTV}/:articleId`}
              component={MLTVContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.MLTV_SEARCH} component={MLTVSearchScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.BROADCASTS_SEARCH}
              component={BroadcastSearchScreen}
            />
            <AvailableRoute
              identifier={'_broadcasts'}
              exact
              path={`${NAVIGATION_ROUTES.BROADCASTS}/:articleId`}
              component={BroadcastContentScreen}
            />
            <Route exact path={['/', NAVIGATION_ROUTES.HOME]} component={HomeScreen} />
            <AvailableRoute
              identifier={'_directory'}
              exact
              path={NAVIGATION_ROUTES.DIRECTORY}
              component={DirectoryScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.MICRO_GIVE}
              component={MicrogiveScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={`${NAVIGATION_ROUTES.DONATION_TABS_SCREEN}/:initiativeId`}
              component={DonationTabsScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={`${NAVIGATION_ROUTES.PROJECT_DETAIL_SCREEN}/:projectId`}
              component={ProjectDetailScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={`${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/:microgiveInitiativeDetailId`}
              component={ProjectTabsScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={`${NAVIGATION_ROUTES.PROJECT_TABS_SCREEN}/:initiativeId/:microgiveInitiativeDetailId`}
              component={ProjectDonationTabsScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={`${NAVIGATION_ROUTES.VOLUNTEER_TABS_SCREEN}/:initiativeId`}
              component={VolunteerTabsScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.VOLUNTEERING_LOG_SCREEN}
              component={VolunteeringLogScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.MY_DONATIONS_SCREEN}
              component={MyDonationsScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.DONATION_SEARCH_SCREEN}
              component={DonationSearchScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.PROJECT_DETAIL_SEARCH_SCREEN}
              component={ProjectDetailSearchScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.SELECT_DATE_SCREEN}
              component={SelectDateScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.VOLUNTEER_PARTICIPATES_SCREEN}
              component={VolunteerParticipatesScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.MICRIGIVE_GALLERY_TAGS_DETAIL_SCREEN}
              component={MicrogiveGalleryTagsDetailScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.UPDATES_SCREEN}
              component={UpdatesScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.QUERY_SCREEN}
              component={QueryScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.DONATION_EVENT_BY_DATE_SCREEN}
              component={DonationEventByDateScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.PROJECT_DETAIL_BY_DATE_SCREEN}
              component={ProjectDetailByDateScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.PROJECTS_SEARCH_SCREEN}
              component={ProjectsSearchScreen}
            />
            <AvailableRoute
              identifier={'_microGive'}
              exact
              path={NAVIGATION_ROUTES.SELECT_DONATION_SCREEN}
              component={SelectDonationScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.HTML_VIEW_SCREEN} component={HTMLViewScreen} />
            <Route path={NAVIGATION_ROUTES.MY_DONATIONS_SCREEN} component={MyDonationsScreen} />
            <AvailableRoute
              identifier={'_posh'}
              exact
              path={NAVIGATION_ROUTES.POSH}
              component={PoshScreen}
            />
            <AvailableRoute
              identifier={'_ceoChat'}
              exact
              path={NAVIGATION_ROUTES.CEO_CHAT}
              component={CeoChatScreen}
            />
            <AvailableRoute
              identifier={'_ceoChat'}
              exact
              path={`${NAVIGATION_ROUTES.CEO_CHAT}/:questionId`}
              component={CeoChatContentScreen}
            />
            <AvailableRoute
              identifier={'_ceoChat'}
              exact
              path={NAVIGATION_ROUTES.CEO_ASK_QUESTION}
              component={AskQuestionScreen}
            />
            <AvailableRoute
              identifier={'_ceoChat'}
              exact
              path={NAVIGATION_ROUTES.CEO_CHAT_COMMENT}
              component={CeoChatCommentScreen}
            />
            <AvailableRoute
              identifier={'_breakouts'}
              exact
              path={NAVIGATION_ROUTES.BREAKOUTS}
              component={BreakoutsScreen}
            />
            <AvailableRoute
              identifier={'_breakouts'}
              path={`${NAVIGATION_ROUTES.BREAKOUTS}/:breakoutId`}
              component={BreakoutsTabScreen}
            />
            <AvailableRoute
              identifier={'_breakouts'}
              exact
              path={NAVIGATION_ROUTES.BREAKOUTS_INFO_DETAIL_SCREEN}
              component={BreakoutsInfoDetailScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.BREAKOUT_GALLERY_SCREEN}
              component={BreakoutsGalleryScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ANNIVERSAY_GALLERY_SCREEN}
              component={BreakoutsGalleryScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.GALLERY_FULL_IMAGE_SCREEN}
              component={GalleryFullImageScreen}
            />
            <AvailableRoute
              identifier={'_esg'}
              exact
              path={NAVIGATION_ROUTES.ESG}
              component={EsgMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.ESG_SEARCH} component={EsgSearchScreen} />
            <AvailableRoute
              identifier={'_esg'}
              path={`${NAVIGATION_ROUTES.ESG_DETAIL}/:articleId`}
              component={EsgContentScreen}
            />
            <AvailableRoute
              identifier={'_mlf'}
              exact
              path={NAVIGATION_ROUTES.MLF}
              component={MlfMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.MLF_SEARCH} component={MlfSearchScreen} />
            <AvailableRoute
              identifier={'_mlf'}
              path={`${NAVIGATION_ROUTES.MLF_DETAIL}/:articleId`}
              component={MlfContentScreen}
            />
            <AvailableRoute
              identifier={'_microlandValues'}
              path={NAVIGATION_ROUTES.MICROLAND_VALUES}
              component={MicrolandValuesMainScreen}
            />
            <AvailableRoute
              identifier={'_emergencyPlaybook'}
              path={NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK}
              component={EmergencyPlaybookMainScreen}
            />
            <AvailableRoute
              identifier={'_emergencyPlaybook'}
              path={NAVIGATION_ROUTES.EMERGENCY_PLAYBOOK_CONTENT}
              component={EmergencyPlaybookContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.SESSION_DETAILS} component={SessionDetailScreen} />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.SESSION_DETAILS}/:sessionId/:speakerId/:questionId`}
              component={SessionDiscussionScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.NEWJOINEE_SESSION_DETAILS}
              component={NewJoineeSessionDetailScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.NEWJOINEE_INDUCTION}
              component={AllInductionSessionTab}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ASK_SESSION_QUESTION}
              component={AskSessionQuestionScreen}
            />
            <AvailableRoute
              identifier={'_anniversaryDay'}
              exact
              path={`${NAVIGATION_ROUTES.ANNIVERSARY_SCREEN}/:identifier`}
              component={AnniversaryScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.ANNIVERSARY_ARTICLE_SCREEN}/:articlesId`}
              component={AnniversaryArticleScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ANNIVERSARY_DAY_GALLERY_FULL_IMAGE}
              component={AnniversaryGalleryFullImageScreen}
            />
            <AvailableRoute
              identifier={'_anniversaryDay'}
              exact
              path={NAVIGATION_ROUTES.ANNIVERSARY_DAY_SCREEN}
              component={AnniversaryDayScreen}
            />
            <AvailableRoute
              identifier={'_anniversaryDay'}
              exact
              path={NAVIGATION_ROUTES.DIGITAL_WARRIOR_SCREEN}
              component={DigitalWarriorScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ADD_CONTESTS_SCREEN}
              component={AddContestsScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ADD_CONTESTS_THANKS_SCREEN}
              component={AddContestsThanksScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.CONTESTS_DETAIL_SCREEN}
              component={ContestsDetailScreen}
            />
            <AvailableRoute
              identifier={'_anniversaryDay'}
              exact
              path={NAVIGATION_ROUTES.MOMENT_TAG_DETAIL_SCREEN}
              component={MomentTagDetailsScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ML35_PICTURES_SCREEN}
              component={ML35PicturesScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN}
              component={GalleryFullScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.PLEDGE_SCREEN} component={PledgeScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.PLEDGE_THANKS_SCREEN}
              component={PledgeThanksScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.TAKE_THE_PLEDGE_SCREEN}
              component={TakeThePledgeScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.WALLPAPER_CONTENT_SCREEN}
              component={WallpaperContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.WALL_PAPERS_SCREEN} component={WallpapersScreen} />
            <Route exact path={NAVIGATION_ROUTES.WATCH_LIVE_SCREEN} component={WatchLiveScreen} />
            <AvailableRoute
              identifier={'_ceoChat'}
              exact
              path={NAVIGATION_ROUTES.CEO_ADD_COMMENT}
              component={CeoChatAddCommentScreen}
            />

            <AvailableRoute
              identifier={'_starsOfML'}
              exact
              path={NAVIGATION_ROUTES.SOM_MAIN}
              component={SomMainScreen}
            />
            <AvailableRoute
              identifier={'_starsOfML'}
              exact
              path={`${NAVIGATION_ROUTES.SOM_MAIN}/:somId`}
              component={SomTabScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.SOM_EMPLOYEE_DETAILS}/:employeeId`}
              component={SomEmployeeDetailsScreen}
            />
            <AvailableRoute
              identifier={'_starsOfML'}
              exact
              path={NAVIGATION_ROUTES.SOM_FOREWORD}
              component={ForwordScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.SOM_GALLERY}/:pictureId`}
              component={SOMGalleryScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.TAG_EMPLOYEE} component={SomTagPeopleScreen} />
            <Route exact path={NAVIGATION_ROUTES.CEO_TAG_EMPLOYEE} component={CeoReplyScreen} />
            <Route exact path={NAVIGATION_ROUTES.TAG_PEOPLE_SCREEN} component={TagPeopleScreen} />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.CHEERS_SCREEN}
              component={CheersScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.CHEERS_DETAIL}
              component={CheersDetailScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.MY_POINTS_SCREEN}
              component={MyPointsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.NOMINATIONS_SCREEN}
              component={NominationsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.SELF_SCREEN}
              component={SelfScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.VIEW_ELIGIBLE_AWARD_SCREEN}
              component={ViewEligibleAwardScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.MY_MANAGER_SCREEN}
              component={MyManagerScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.PEER_SCREEN}
              component={PeerScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.VIEW_PEER_AWARD_SCREEN}
              component={ViewPeerAwardScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.VIEW_PEER_AWARD_SCREEN}
              component={ViewPeerAwardScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.MY_TEAM_SCREEN}
              component={MyTeamScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.VIEW_MY_TEAM_AWARD_SCREEN}
              component={ViewMyTeamAwardScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.MY_POINTS_SCREEN}
              component={MyPointsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.REDEEM_POINTS_SCREEN}
              component={RedeemPointsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.DONATE_POINTS_SCREEN}
              component={DonatePointsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.VIEW_NOMINATIONS_SCREEN}
              component={ViewNominationsScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.POINTS_REDEEMED_SCREEN}
              component={PointsRedeemedScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.REWARD_DETAILS_SCREEN}
              component={RewardDetailScreen}
            />
            <CheersProtectedRoute
              exact
              path={NAVIGATION_ROUTES.TAGGED_PEOPLE_SCREEN}
              component={TaggedPeopleScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.PROFILE_CHEERS_LISTING_SCREEN}
              component={ProfileCheersListingScreen}
            />
            <AvailableRoute
              identifier={'_celebrations'}
              exact
              path={`${NAVIGATION_ROUTES.CELEBRATIONS}/:wishType/:employeeId/:year`}
              component={WishesScreen}
            />
            <AvailableRoute
              identifier={'_womensDay'}
              exact
              path={NAVIGATION_ROUTES.WOMENS_DAY_LISTING}
              component={WomensDayListingScreen}
            />
            <AvailableRoute
              identifier={'_womensDay'}
              exact
              path={`${NAVIGATION_ROUTES.WOMENS_DAY}/:id`}
              component={WomensDayMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.COMMENTS} component={CommentScreen} />
            <Route exact path={NAVIGATION_ROUTES.THE_WALL_COMMENT} component={CommentScreenWall} />
            <AvailableRoute
              identifier={'_womensDay'}
              exact
              path={NAVIGATION_ROUTES.WOMENS_DAY_GALLERY}
              component={WomensDayGalleryScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.WOMENS_DAY_LISTING}/:id${NAVIGATION_ROUTES.WOMENS_DAY_WATCH_VIDEO}`}
              component={WomensDayWatchVideoScreen}
            />
            <AvailableRoute
              identifier={'_womensDay'}
              exact
              path={NAVIGATION_ROUTES.WOMENS_DAY_GALLERY_FULL_IMAGE}
              component={WomensDayGalleryfullImageScreen}
            />
            <AvailableRoute
              identifier={'_businessDashboard'}
              exact
              path={NAVIGATION_ROUTES.BUSINESS_DASHBOARD}
              component={BusinessDashboard}
            />
            <AvailableRoute
              identifier={'_salesIncentive'}
              exact
              path={NAVIGATION_ROUTES.SALES_INCENTIVE}
              component={SalesIncentiveScreen}
            />
            <AvailableRoute
              identifier={'_profile'}
              exact
              path={NAVIGATION_ROUTES.MY_PROFILE_ASSET}
              component={MyAssetScreen}
            />
            <AvailableRoute
              identifier={'_profile'}
              exact
              path={NAVIGATION_ROUTES.MY_ASSET_DETAIL}
              component={MyAssetDetailScreen}
            />
            <AvailableRoute
              identifier={'_profile'}
              exact
              path={NAVIGATION_ROUTES.MY_PROFILE_DETAIL}
              component={MyProfileDetailScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.ALL_WISHES} component={AllWishesScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.CELEBRATION_WISHES}
              component={CelebrationWishesScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.EMPLOYEE_PROFILE_DETAIL}/:employeeId`}
              component={EmployeeProfileDetailScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.ORG_STRUCTURE} component={OrgStructureScreen} />
            <AvailableRoute
              identifier={'_profile'}
              exact
              path={NAVIGATION_ROUTES.MY_PROFILE_DETAIL_EDIT}
              component={MyProfileDetailEditScreen}
            />
            <AvailableRoute
              identifier={'_news'}
              exact
              path={NAVIGATION_ROUTES.NEWS}
              component={NewsMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.NEWS_SEARCH} component={NewsSearchScreen} />
            <AvailableRoute
              identifier={'_news'}
              exact
              path={`${NAVIGATION_ROUTES.NEWS}/:articleId`}
              component={NewsContentScreen}
            />
            <AvailableRoute
              identifier={'_covidSupport'}
              exact
              path={NAVIGATION_ROUTES.PANDEMIC_SUPPORT}
              component={PandemicSupportTabScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.FULL_SCREEN_VIDEO}/:videoId`}
              component={FullScreenVideoScreen}
            />
            <AvailableRoute
              identifier={'_miHRSupport'}
              exact
              path={NAVIGATION_ROUTES.MI_HR_SUPPORT}
              component={MiHRMainScreen}
            />
            <AvailableRoute
              identifier={'_miHRSupport'}
              exact
              path={`${NAVIGATION_ROUTES.MI_HR_SUPPORT}/:articleId`}
              component={MiHRContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.MI_HR_SEARCH} component={MiHRSearchScreen} />
            <Route exact path={NAVIGATION_ROUTES.LIKED_PEOPLE} component={LikedPeopleScreen} />
            <AvailableRoute
              identifier={'_policies'}
              exact
              path={NAVIGATION_ROUTES.POLICIES}
              component={PoliciesScreen}
            />

            <Route
              exact
              path={NAVIGATION_ROUTES.POLICY_SEARCH_SCREEN}
              component={PolicySearchScreen}
            />
            <AvailableRoute
              identifier={'_policies'}
              exact
              path={`${NAVIGATION_ROUTES.POLICIES}/:policyId`}
              component={PolicyDetailScreen}
            />
            <AvailableRoute
              exact
              identifier={'_dearML'}
              path={NAVIGATION_ROUTES.DEAR_EMPLOYER}
              component={DearEmployerMainScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.DEAR_EMPLOYER_SEARCH}
              component={DearEmployerSearchScreen}
            />
            <AvailableRoute
              exact
              identifier={'_dearML'}
              path={`${NAVIGATION_ROUTES.DEAR_EMPLOYER}/:articlesId`}
              component={DearEmployerContentScreen}
            />
            <AvailableRoute
              identifier={'_events'}
              exact
              path={NAVIGATION_ROUTES.EVENTS}
              component={EventMainScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.EVENTS}/:eventId`}
              component={EventContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.EVENTS_SEARCH} component={EventsSearchScreen} />
            <AvailableRoute
              identifier={'_events'}
              exact
              path={NAVIGATION_ROUTES.EVENTS_DATE}
              component={EventDateScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.EVENTS_GALLERY} component={EventGalleryScreen} />
            <AvailableRoute
              identifier={'_celebrations'}
              exact
              path={NAVIGATION_ROUTES.CELEBRATIONS}
              component={CelebrationsScreen}
            />
            <AvailableRoute
              identifier={'_polls'}
              exact
              path={NAVIGATION_ROUTES.POLLS_AND_SURVEYS}
              component={PollsAndSurveysMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.POLLS_RESULTS} component={PollViewResultScreen} />
            <Route
              path={`${NAVIGATION_ROUTES.POLLS_NOTIFICATION_SCREEN}/:pollId`}
              component={PollsNotificationScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.SURVEYS_PARTICIPATE}
              component={SurveyParticipateScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.SURVEY_SUCCESS} component={SurveySuccessScreen} />
            <Route
              path={`${NAVIGATION_ROUTES.SURVEYS_NOTIFICATION_SCREEN}/:surveyId`}
              component={SurveysNotificationScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.DOWNLOAD_APP} component={DownloadAppScreen} />
            <AvailableRoute
              identifier={'_gcdo'}
              exact
              path={NAVIGATION_ROUTES.GCDO}
              component={GcdoMainScreen}
            />
            <AvailableRoute
              identifier={'_gcdo'}
              path={`${NAVIGATION_ROUTES.GCDO_DETAIL}/:articleId`}
              component={GcdoContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.GCDO_SEARCH} component={GcdoSearchScreen} />
            <AvailableRoute
              identifier={'_contests'}
              exact
              path={NAVIGATION_ROUTES.CONTESTS}
              component={ContestMainScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.CONTESTS}/:contestId`}
              component={ContestContentScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.CONTESTS_SEARCH}
              component={ContestsSearchScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.CONTEST_GALLERY}
              component={ContestGalleryScreen}
            />
            <AvailableRoute
              identifier={'_contests'}
              exact
              path={NAVIGATION_ROUTES.CONTEST_DATE}
              component={ContestDateScreen}
            />
            <AvailableRoute
              identifier={'_ourCustomers'}
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER}
              component={OurCustomerMainScreen}
            />
            <AvailableRoute
              identifier={'_ourCustomers'}
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER_RESEARCHERS_CONTENT}
              component={OurCustomersResearchersDetails}
            />
            <AvailableRoute
              identifier={'_ourCustomers'}
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT}
              component={OurCustomerContentScreen}
            />
            <AvailableRoute
              identifier={'_ourCustomers'}
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER_SEARCH_SCREEN}
              component={OurCustomerSearchScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER_KNOW_SEARCH_SCREEN}
              component={OurCustomerKnowSearchScreen}
            />
            <AvailableRoute
              identifier={'_ourCustomers'}
              exact
              path={NAVIGATION_ROUTES.OUR_CUSTOMER_ALL_SCREEN}
              component={OurCustomerAllMainScreen}
            />
            <AvailableRoute
              identifier={'_gallery'}
              exact
              path={NAVIGATION_ROUTES.GALLERY}
              component={GalleryMainScreen}
            />
            <AvailableRoute
              identifier={'_gallery'}
              exact
              path={NAVIGATION_ROUTES.GALLERY_SEARCH}
              component={GallerySearchScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.GALLERY_COMMON} component={GalleryScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.ML_DAY_GALLERY_SCREEN}
              component={MlDayGalleryFullScreen}
            />

            <AvailableRoute
              identifier={'_facilities'}
              exact
              path={NAVIGATION_ROUTES.FACILITY}
              component={FacilitiesMainScreen}
            />
            <AvailableRoute
              identifier={'_facilities'}
              exact
              path={`${NAVIGATION_ROUTES.FACILITY}/:facilityId`}
              component={FacilityContentScreen}
            />
            <AvailableRoute
              identifier={'_newJoinees'}
              exact
              path={NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS}
              component={NewJoineesLowerLevelsScreen}
            />
            <AvailableRoute
              identifier={'_newJoinees'}
              exact
              path={`${NAVIGATION_ROUTES.NEW_JOINEES_LOWER_LEVELS}/:articleId`}
              component={MiHRContentScreen}
            />
            <AvailableRoute
              identifier={'_newJoinees'}
              exact
              path={`${NAVIGATION_ROUTES.NEO_SESSION_DETAIL}/:neoId`}
              component={NeoSessionDetailMainScreen}
            />
            <AvailableRoute
              identifier={'_communities'}
              exact
              path={NAVIGATION_ROUTES.COMMUNITIES}
              component={CommunitiesMainScreen}
            />
            <Route
              path={`${NAVIGATION_ROUTES.COMMUNITIES_DETAIL}/:communityId`}
              component={CommunityDetailScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.VIEW_MEMBERS}/:communityId`}
              component={ViewMembersScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.VIEW_COMMUNITY_POSTS}/:communityId`}
              component={ViewCommunityPosts}
            />
            {/* <Route
              exact
              path={`${NAVIGATION_ROUTES.COMMUNITIES_MAINSCREEN_POSTS}`}
              component={CommunitiesMainScreenPostScreen}
            /> */}
            <Route
              exact
              path={`${NAVIGATION_ROUTES.POST_DETAIL}/:postId`}
              component={PostDetailScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.WRITE_POST} component={WritePostScreen} />
            <Route exact path={NAVIGATION_ROUTES.EDIT_POST} component={EditPostScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.COMMUNITY_POST_TAGGED}
              component={CommunityTagPeopleScreen}
            />
            <AvailableRoute
              identifier={'_ecards'}
              exact
              path={NAVIGATION_ROUTES.ECARDS}
              component={EcardMainScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.ECARDS_CONTENT} component={EcardContentScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.NOTIFICATION_LISTING}
              component={NotificationListingScreen}
            />
            <AvailableRoute
              identifier={'_theWall'}
              exact
              path={NAVIGATION_ROUTES.THE_WALL}
              component={TheWallMainScreen}
            />
            <AvailableRoute
              identifier={'_theWall'}
              exact
              path={NAVIGATION_ROUTES.THE_WALL_EDIT_POST}
              component={TheWallNewPostScreen}
            />
            <AvailableRoute
              identifier={'_theWall'}
              exact
              path={NAVIGATION_ROUTES.THE_WALL_CREATE_POST}
              component={TheWallNewPostScreen}
            />
            <AvailableRoute
              identifier={'_theWall'}
              exact
              path={`${NAVIGATION_ROUTES.THE_WALL_POST_DETAIL}/:postId`}
              component={TheWallPostDetailScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.THE_WALL_TAGGED_PEOPLE}
              component={TheWallTaggedPeopleScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.HOLIDAY_SCREEN} component={HolidayScreen} />
            <Route exact path={NAVIGATION_ROUTES.PDF_FULL_SCREEN} component={PdfViewerScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.GLOBAL_SEARCH}
              component={GlobalSearchMainScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.TERMS_CONDITIONS_SCREEN}
              component={TermsConditionsScreen}
            />
            <AvailableRoute
              identifier={'_microKnow'}
              exact
              path={NAVIGATION_ROUTES.MICROKNOW}
              component={MicroKnowScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.MICROKNOW_SEARCH}
              component={MicroKnowSearchScreen}
            />
            <AvailableRoute
              identifier={'_legal'}
              exact
              path={NAVIGATION_ROUTES.MICRO_LAW}
              component={MicroLawScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.MICRO_LAW_CONTENT}/:articleId`}
              component={MicroLawContentDetailScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={NAVIGATION_ROUTES.IJPR}
              component={IjprScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={NAVIGATION_ROUTES.IJPR_SEARCH_RESULT}
              component={IjprSearchResultScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={NAVIGATION_ROUTES.IJP_REFER_SEARCH_RESULT}
              component={IjpReferSearchResultScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={NAVIGATION_ROUTES.IJP_REFER_ELIGIBLE_SEARCH_RESULT}
              component={IjpReferSearchResultScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={`${NAVIGATION_ROUTES.IJPR_DETAIL}/:ijpId`}
              component={IjprDetailScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={`${NAVIGATION_ROUTES.IJP_REFER_DETAIL}/:ijpId`}
              component={IjpReferDetailScreen}
            />
            <AvailableRoute
              identifier={'_dni'}
              exact
              path={NAVIGATION_ROUTES.DI}
              component={DIScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.DI_SEARCH} component={DISearchScreen} />
            <AvailableRoute
              identifier={'_dni'}
              exact
              path={NAVIGATION_ROUTES.DI_EVENT_DATE}
              component={DIEventDateScreen}
            />
            <Route exact path={`${NAVIGATION_ROUTES.DI}/:articleId`} component={DIContentScreen} />
            <AvailableRoute
              identifier={'_microBiz'}
              exact
              path={NAVIGATION_ROUTES.MICROBIZ}
              component={MicrobizScreen}
            />
            <AvailableRoute
              identifier={'_microBiz'}
              path={`${NAVIGATION_ROUTES.MICROBIZ_DETAIL}/:articleId`}
              component={MicrobizContentScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.MICROBIZ_SEARCH}
              component={MicrobizSearchScreen}
            />
            <AvailableRoute
              identifier={'_bto'}
              exact
              path={NAVIGATION_ROUTES.BTO}
              component={BtoScreen}
            />
            <AvailableRoute
              identifier={'_bto'}
              path={`${NAVIGATION_ROUTES.BTO_DETAIL}/:articleId`}
              component={BtoContentScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.BTO_SEARCH} component={BtoSearchScreen} />
            <AvailableRoute
              identifier={'_podcasts'}
              exact
              path={NAVIGATION_ROUTES.PODCASTS}
              component={PodcastScreen}
            />
            <AvailableRoute
              identifier={'_podcasts'}
              exact
              path={`${NAVIGATION_ROUTES.PODCASTS_CONTENT}/:channelId`}
              component={PodcastContentScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.PODCASTS_SEARCH}
              component={PodcastsSearchScreen}
            />
            <AvailableRoute
              identifier={'_podcasts'}
              exact
              path={`${NAVIGATION_ROUTES.PODCASTS_EPISODE_DETAIL}/:episodeId`}
              component={PodcastEpisodeDetailScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.CHAT} component={ChatScreen} />
            <AvailableRoute
              identifier={'_wallpapers'}
              exact
              path={NAVIGATION_ROUTES.WALLPAPERS}
              component={WallpaperScreen}
            />
            <AvailableRoute
              identifier={'_travel'}
              exact
              path={NAVIGATION_ROUTES.TRAVEL_REQUEST}
              component={TravelRequestScreen}
            />
            <AvailableRoute
              identifier={'_travel'}
              exact
              path={`${NAVIGATION_ROUTES.TRAVEL_REQUEST_REVIEW}/:requestId`}
              component={TravelRequestReviewScreen}
            />
            <AvailableRoute
              identifier={'_ijpr'}
              exact
              path={NAVIGATION_ROUTES.IJPR_SAVED_JOBS}
              component={IjprBookmarkScreen}
            />
            {/* POLICY_TAB_SCREEN is not in use  */}
            <Route
              exact
              path={`${NAVIGATION_ROUTES.POLICY_TAB_SCREEN}/:geographyId`}
              component={PolicyTabsScreen}
            />
            <AvailableRoute
              identifier={'_posh'}
              exact
              path={NAVIGATION_ROUTES.POSH_RAISE_CONCERN}
              component={RaiseConcernScreen}
            />
            <AvailableRoute
              identifier={'_microlandMonday'}
              exact
              path={NAVIGATION_ROUTES.ML_MONDAY}
              component={MLMondayScreen}
            />
            <AvailableRoute
              identifier={'_microlandMonday'}
              exact
              path={`${NAVIGATION_ROUTES.ML_MONDAY_CONTENT}/:articlesId`}
              component={MLMondayContentScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.ML_MONDAY_SEARCH}
              component={MLMondaySearchScreen}
            />
            <AvailableRoute
              identifier={'_variablePay'}
              exact
              path={NAVIGATION_ROUTES.VARIABLE_PAY}
              component={VariablePayScreen}
            />
            <Route
              exact
              path={NAVIGATION_ROUTES.NOTIFICATION_SEARCH_SCREEN}
              component={NotificationSearchScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.FEEDBACK_SCREEN} component={FeedbackScreen} />
            <Route
              exact
              path={NAVIGATION_ROUTES.MICROGIVE_FEEDBACK_SCREEN}
              component={MicrogiveFeedbackScreen}
            />
            <AvailableRoute
              identifier={'_askOrg'}
              exact
              path={NAVIGATION_ROUTES.ASK_ORG}
              component={AskMlScreen}
            />
            <AvailableRoute
              identifier={'_askOrg'}
              exact
              path={NAVIGATION_ROUTES.ASK_ORG_SEARCH_RESULTS_SCREEN}
              component={AskMlSearchResultsScreen}
            />
            <AvailableRoute
              identifier={'_askOrg'}
              exact
              path={NAVIGATION_ROUTES.ASK_ORG_RESULTS_DETAIL}
              component={AskOrgSearchResultDetailScreen}
            />
            <AvailableRoute
              identifier={'_miAuction'}
              exact
              path={NAVIGATION_ROUTES.MI_AUCTION}
              component={MiAuctionMainScreen}
            />
            <AvailableRoute
              identifier={'_miAuction'}
              exact
              path={`${NAVIGATION_ROUTES.MI_AUCTION}/:auctionId`}
              component={MiAuctionDetailsScreen}
            />
            <AvailableRoute
              identifier={'_miAuction'}
              exact
              path={NAVIGATION_ROUTES.MI_AUCTION_TC}
              component={MiAuctionTCScreen}
            />
            <AvailableRoute
              identifier={'_michildcare'}
              exact
              path={NAVIGATION_ROUTES.MI_CHILD_CARE}
              component={MichildcareMainScreen}
            />
            <AvailableRoute
              identifier={'_michildcare'}
              exact
              path={NAVIGATION_ROUTES.MI_CHILD_CARE_CONTENT}
              component={ChildCareContentScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MICRO_STORE_MAIN_SCREEN}
              component={MicrostoreMainScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={`${NAVIGATION_ROUTES.MICRO_STORE_POLICY_SCREEN}/:policyId`}
              component={MiStorePolicyScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={`${NAVIGATION_ROUTES.MICRO_STORE_ALL_PRODUCTS_SCREEN}/:categoryId?`}
              component={MiStoreAllProductsScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={`${NAVIGATION_ROUTES.MICRO_STORE_PRODUCT_SCREEN}/:sku`}
              component={MiStoreProductDetailsScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MICRO_STORE_CART_SCREEN}
              component={MiStoreCartScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_CONFIRM_ADDERSS}
              component={DeliveryEstimateScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ORDER_TC}
              component={OrderTermsAndConditions}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ORDER_SUCCESS}
              component={OrderSuccessScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ADDRESS_LIST_SCREEN}
              component={AddressListScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ADD_ADDRESS_SCREEN}
              component={EStoreAddAddressScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={`${NAVIGATION_ROUTES.MI_STORE_EDIT_ADDRESS_SCREEN}/:addressId`}
              component={EStoreEditAddressScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_SELECT_ADDRESS_SCREEN}
              component={SelectAddressScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ORDER_LIST_SCREEN}
              component={OrderHistoryListScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_SHIPMENT_DETAILS}
              component={ShipmentDetailsScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_ORDER_DETAILS}
              component={OrderDetailsScreen}
            />
            <AvailableRoute
              identifier={'_microStore'}
              exact
              path={NAVIGATION_ROUTES.MI_STORE_INVOICE_SCREEN}
              component={InvoiceScreen}
            />
            <Route
              path={`${NAVIGATION_ROUTES.SEAT_BOOKING_MAIN_SCREEN}`}
              component={SeatBookingMainScreen}
            />
            <Route
              path={`${NAVIGATION_ROUTES.VEHICLE_PARKING_MAIN_SCREEN}`}
              component={VehicleParkingMainScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.BOOK_A_PARKING_SCREEN}`}
              component={BookAParkingTab}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.BOOK_A_SEAT_SCREEN}`}
              component={BookASpaceTab}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.VEHICLE_BOOKING_PREVIEW_SCREEN}`}
              component={BookingPreviewScreen}
            />
            <Route
              exact
              path={`${NAVIGATION_ROUTES.SEAT_BOOKING_PREVIEW_SCREEN}`}
              component={SeatBookingPreviewScreen}
            />
            <Route exact path={NAVIGATION_ROUTES.DOWNLOAD} component={DownloadAppPopupWeb} />
            <Route component={PageNotFoundScreen} />
          </Switch>
        )}
      </ErrorBoundary>
    </div>
  );
};

export default Navigation;
