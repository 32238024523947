import React from 'react';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
} from '../../../common/constants/AppConstants';
import Divider from '../../../common/ui/divider/Divider';

const OfficeItem = ({ item, index }) => {
  const accessibilityData = useSelector(selectAccessibilityData);
  return (
    <div className="w-100">
      <div className="d-flex flex-row mt-2 py-2 w-100">
        <img
          src={getFeatureComponentUrl(
            accessibilityData,
            ACCESSIBILITY_FEATURE_COMPONENTS.OFFICE_LOCATION_ICON,
            ACCESSIBILITY_IDENTIFIERS.VEHICLE_PARKING
          )}
          className="seat-booking-office-location-icon mr-2"
        />

        <div className="d-flex flex-row justify-content-between w-100 align-items-center">
          <div className="w-80">
            <div className="base-14px-font mr-4">{item.label}</div>
          </div>
          {item.spacesCount > 1 ? (
            <div className="base-14px-font">{item.spacesCount} desks</div>
          ) : (
            <div className="base-14px-font">{item.spacesCount} desk</div>
          )}
        </div>
      </div>
      {index === 0 && <Divider />}
    </div>
  );
};

export default OfficeItem;
