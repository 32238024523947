import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import CommentBox from '../../../common/ui/comment_box/CommentBox';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { WOMENS_DAY_DISCUSSION, WOMENS_DAY_POST_DISCUSSION } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../../state/UIState';
import { isEmptyText, showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DiscussionTabItem from '../components/DiscussionTabItem';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const ConvoTab = (props) => {
  const [discussions, setDiscussions] = useState([]);
  const [commentText, setCommentText] = useState();
  const [showLoadMoreFlag, setShowLoadMoreFlag] = useState(true);
  const loadMoreFlag = useRef(true);
  const [pageId, setPageId] = useState(1);
  const dispatch = useDispatch();
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const [loading, setLoading] = useState(true);
  const year = props?.year ?? '';
  const accessibilityData = useSelector(selectAccessibilityData);

  const callDiscussionListApi = useCallback(
    async (id) => {
      if (loadMoreFlag.current) {
        setLoading(true);
        const params = {
          page_id: id || 1,
          year: year,
        };
        try {
          const apiResponse = await apiRequest(WOMENS_DAY_DISCUSSION, REQUEST_TYPE.GET, params);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussions((oldArray) => {
                  const newDiscussions = [...apiResponse.response.data.feeds.reverse()];
                  const existingDiscussionIds = new Set(oldArray.map((item) => item.discussion_id));
                  const uniqueDiscussions = newDiscussions.filter((item) => {
                    if (!existingDiscussionIds.has(item.discussion_id)) {
                      existingDiscussionIds.add(item.discussion_id);
                      return true;
                    }
                    return false;
                  });

                  return [...uniqueDiscussions, ...oldArray];
                });

                setPageId(id);
                loadMoreFlag.current = true;
              } else {
                loadMoreFlag.current = false;
                setShowLoadMoreFlag(false);
              }
            }
          }
          setLoading(false);
        } catch (err) {
          handleError(err, params, WOMENS_DAY_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
          setLoading(false);
        }
      }
    },
    [year]
  );

  useEffect(() => {
    callDiscussionListApi(1);
  }, []);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.WOMENS_DAY_CONVO_TAB,
      props.item,
      ANALYTICS_ITEM_NAMES.WOMENS_DAY
    );
  }, [props.item]);

  const callCommentApi = async () => {
    const params = new URLSearchParams();
    params.append('description', commentText);
    params.append('year', year);
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await apiRequest(WOMENS_DAY_POST_DISCUSSION, REQUEST_TYPE.POST, params);
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          loadMoreFlag.current = true;
          setDiscussions([]);
          callDiscussionListApi(1);
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      handleError(err, params, WOMENS_DAY_POST_DISCUSSION, NAVIGATION_ROUTES.WOMENS_DAY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      callCommentApi();
    }
  };

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId + 1);
  };

  return (
    <div>
      <div className="womens-day-chart-list-style">
        <ActivityLoader visible={loading} />
        {showLoadMoreFlag && (
          <div className="mb-2 mt-4">
            <div className="loadMoreContainer">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                  ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                )}
                className="load-more-icon mr-2"
                onClick={onPressLoadPrevious}
              />
              <div className="loadmoreText" onClick={onPressLoadPrevious}>
                Load previous comments
              </div>
            </div>
          </div>
        )}
        {discussions.map((item, index) => {
          return <DiscussionTabItem key={`${index}`} item={item} />;
        })}
      </div>
      <div className="womens-day-comment-box">
        <CommentBox
          getCommentText={onPressSend}
          onChangehandler={(val) => {
            setCommentText(val);
          }}
          commentText={commentText}
          showLoader={progressLoadingState.isProgressLoading}
        />
      </div>
    </div>
  );
};

export default ConvoTab;
