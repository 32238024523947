import React, { useEffect, useState, useCallback } from 'react';
import {
  ACCESSIBILITY_HEADER_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  OUR_CUSTOMERS_API_TYPES,
  OUR_CUSTOMERS_DATA_TYPES,
  OUR_CUSTOMERS_LIKE_TYPES,
  REQUEST_TYPE,
  SEARCH_DEBOUNCE_WAIT_TIME,
} from '../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../services/Service';
import { CUSTOMERS_BY_ID } from '../../../services/ApiUrls';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import OurCustomerListItem from '../components/OurCustomerListItem';
import { useHistory } from 'react-router';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { getHeaderComponentUrl } from '../../../utils/accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import debounce from 'lodash.debounce';
import { saveSearchText, selectSearchText } from '../../../state/UIState';

const OurCustomerSearchScreen = ({ title, location }) => {
  const history = useHistory();
  const [yearCustomers, setYearCustomers] = useState({});
  const [years, setYears] = useState([]);
  const [yearActive, setYearsActive] = useState({});
  const [yearPageId, setYearPageId] = useState({ LATEST: 1 });
  const [yearLoadMore, setYearLoadMore] = useState({ LATEST: true });
  const [curYear, setCurYear] = useState('LATEST');
  const [loading, setLoading] = useState(false);
  const [dealYearList, setDealYearList] = useState([]);
  const [feeds, setfeeds] = useState([]);
  const accessibilityData = useSelector(selectAccessibilityData);
  const [searchList, setSearchList] = useState([]);
  const searchData = useSelector(selectSearchText);
  const [searchText, setSearchText] = useState(searchData ?? '');
  const type = location?.state?.type;
  const dispatch = useDispatch();

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.OUR_CUSTOMERS_TAB_VISIT,
      type,
      ANALYTICS_ITEM_NAMES.OUR_CUSTOMER
    );
  }, [type]);

  useEffect(() => {
    if (searchText !== '') {
      callOurCustomerApi(searchText, 1);
    }
  }, [callOurCustomerApi, searchText]);

  const setYearData = (data) => {
    if (years.length === 0) {
      let arr = data.years;
      arr.unshift('LATEST');
      setYears(arr);
      let yearsStatus = {};
      let yearPageId = {};
      let yearCustomers = {};
      let yearLoadMore = {};
      arr.forEach((element, index) => {
        if (index === 0) {
          yearsStatus[element] = true;
          yearPageId[element] = 1;
          yearCustomers[element] = [];
          yearLoadMore[element] = true;
        } else {
          yearsStatus[element] = false;
          yearPageId[element] = 1;
          yearCustomers[element] = [];
          yearLoadMore[element] = true;
        }
      });
      setYearsActive(yearsStatus);
      setYearCustomers(yearCustomers);
      setYearPageId(yearPageId);
      setYearLoadMore(yearLoadMore);
    }
  };

  const callOurCustomerApi = useCallback(
    async (pageId, text) => {
      if (yearLoadMore[curYear] === true) {
        const get_params = {
          page_id: pageId,
          query: text,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            CUSTOMERS_BY_ID + type,
            REQUEST_TYPE.GET,
            get_params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data) {
                const data = apiResponse.response.data;
                setfeeds(data.feeds);
                if (data?.deal_years?.length > 0) {
                  setDealYearList(data?.deal_years);
                }
                if (data.years && data.years.length > 0) {
                  setYearData(data);
                }
                if (pageId === 1) {
                  setYearCustomers({});
                }
                if (data.feeds && data.feeds.length > 0) {
                  let tempCustomers = [];
                  if (yearCustomers[curYear]) {
                    tempCustomers = [...yearCustomers[curYear], ...data.feeds];
                  } else {
                    tempCustomers = data.feeds;
                  }
                  setYearCustomers((prev) => ({
                    ...prev,
                    [curYear]: tempCustomers,
                  }));
                } else {
                  setYearLoadMore((prev) => ({ ...prev, [curYear]: false }));
                }
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            get_params,
            CUSTOMERS_BY_ID + type,
            NAVIGATION_ROUTES.OUR_CUSTOMER_SEARCH_SCREEN
          );
          setLoading(false);
        }
      }
    },
    [type]
  );

  const debouncedCallback = (event) => {
    if (event && event.target.value.length > 2) {
      dispatch(saveSearchText(event.target.value));
      setYearPageId({ LATEST: 1 });
      setYearLoadMore({ LATEST: true });
      callOurCustomerApi(1, event.target.value);
    }
    setSearchText(event.target.value);
  };

  const debounceInputHandler = useCallback(
    debounce(debouncedCallback, SEARCH_DEBOUNCE_WAIT_TIME),
    []
  );

  const onInputChange = (event) => {
    setSearchText(event.target.value);
    debounceInputHandler(event);
  };

  const onPressLoadMore = () => {
    const tempPageId = yearPageId[curYear] + 1;
    setYearPageId((prev) => ({ ...prev, [curYear]: tempPageId }));
  };

  const onPressMore = (pictures, baseUrl, id) => {
    history.push(NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN, {
      baseUrl: baseUrl,
      data: pictures.pictures,
      title: title,
      type: 'clientvisits',
      id: id,
    });
  };

  const onPress = (item) => {
    history.push(NAVIGATION_ROUTES.OUR_CUSTOMER_CONTENT, {
      customerId: item.id,
      dataType: OUR_CUSTOMERS_DATA_TYPES[type],
      parentName: title,
      apiType: OUR_CUSTOMERS_API_TYPES[type],
      type: type,
      likeType: OUR_CUSTOMERS_LIKE_TYPES[type],
    });
  };

  const renderItem = (item, index) => {
    return (
      <OurCustomerListItem
        item={item}
        key={`${index}`}
        onPressMore={onPressMore}
        onPress={onPress}
      />
    );
  };

  const callSubmitFunction = (event) => {
    event.preventDefault();
    if (searchText !== '') {
      logEvent(
        ANALYTICS_EVENT_TYPES.MICROGIVE_PROJECT_SEARCH,
        searchText,
        ANALYTICS_ITEM_NAMES.SEARCH
      );
      setSearchList([]);
      callOurCustomerApi(1, searchText);
    }
  };

  const onTextChange = (event) => {
    setSearchText(event.target.value);
  };

  const resetValue = () => {
    setSearchText('');
  };

  return (
    <div className="container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.OUR_CUSTOMERS} />
      <BackButton />
      <div className="header-view d-flex justify-content-between">
        <form className="microgive-search-form" onSubmit={callSubmitFunction}>
          <input
            autoFocus
            className="ml-3 microgive-search-bar"
            type="text"
            placeholder="Search.."
            onChange={onInputChange}
            value={searchText}
          />
        </form>
        <img
          src={getHeaderComponentUrl(
            accessibilityData,
            ACCESSIBILITY_HEADER_COMPONENTS.SEARCH_CANCEL
          )}
          className="header-icon mr-3"
          onClick={resetValue}
        />
      </div>
      <div className="row">
        {years?.length > 0 &&
        yearCustomers &&
        Object?.keys(yearCustomers)?.length > 0 &&
        feeds.length > 0 ? (
          yearCustomers[curYear] &&
          yearCustomers[curYear]?.map((item, index) => renderItem(item, index))
        ) : (
          <div className="w-100">
            <DefaultContentView message={ERROR_MESSAGES.NO_DATA_FOUND} />
          </div>
        )}
      </div>
      <ActivityLoader visible={loading} />
      {/* {yearLoadMore[curYear] === true && (
        <LoadMoreBtn onClick={onPressLoadMore} isLoading={loading} />
      )} */}
    </div>
  );
};

export default OurCustomerSearchScreen;
