import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  LOADING_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import CommentBox from '../../../common/ui/comment_box/CommentBox';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import LoadMoreBtn from '../../../common/ui/load_more_btn/LoadMoreBtn';
import { GET_DISCUSSION_LIST, SUBMIT_DISCUSSION_COMMENT } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import { isEmptyText, showToast } from '../../../utils/common';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import DiscussionTabItem from '../../microgive/components/DiscussionTabItem';
import { getFeatureComponentUrl } from '../../../utils/accessibility';
import { selectAccessibilityData } from '../../../state/AccessibilityData';

const DiscussionTab = ({ breakoutId }) => {
  const dispatch = useDispatch();
  const [discussionsList, setDiscussionsList] = useState([]);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [pageId, setPageId] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [loading, setLoading] = useState(true);
  const accessibilityData = useSelector(selectAccessibilityData);

  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.BREAKOUTS_DISCUSSIONS_TAB_VISIT,
      breakoutId,
      ANALYTICS_ITEM_NAMES.BREAKOUTS
    );
  }, [breakoutId]);

  const callDiscussionListApi = useCallback(
    async (id, loadMore) => {
      dispatch(saveShowEmptyContentView(true));
      if (loadMore) {
        const params = {
          page_id: id,
        };
        try {
          setLoading(true);
          const apiResponse = await apiRequest(
            GET_DISCUSSION_LIST + breakoutId + '/discussion',
            REQUEST_TYPE.GET,
            params
          );
          setLoading(false);
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.feeds.length > 0) {
                setDiscussionsList((oldArray) => [
                  ...apiResponse.response.data.feeds.reverse(),
                  ...oldArray,
                ]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
        } catch (err) {
          handleError(
            err,
            params,
            GET_DISCUSSION_LIST + breakoutId + '/discussion',
            NAVIGATION_ROUTES.BREAKOUTS
          );
          setLoading(false);
        }
      }
    },
    [breakoutId, dispatch]
  );

  useEffect(() => {
    callDiscussionListApi(1, true);
  }, [callDiscussionListApi]);

  const onPressLoadPrevious = () => {
    callDiscussionListApi(pageId, loadMoreFlag);
  };

  const handleChange = (value) => {
    setCommentText(value);
  };

  const callCommentApi = async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
          progressMessage: LOADING_MESSAGES.POSTING_DISCUSSION,
        })
      );
      const params = new URLSearchParams();
      params.append('description', commentText.trim());
      const apiResponse = await apiRequest(
        SUBMIT_DISCUSSION_COMMENT + breakoutId + '/post',
        REQUEST_TYPE.POST,
        params
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          setCommentText('');
          setPageId(1);
          setDiscussionsList([]);
          setLoadMoreFlag(true);
          callDiscussionListApi(1, true);
        }
      }
    } catch (err) {
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const onPressSend = async () => {
    if (!isEmptyText(commentText)) {
      showToast(ERROR_MESSAGES.EMPTY_TEXT);
    } else {
      logEvent(
        ANALYTICS_EVENT_TYPES.BREAKOUTS_DISCUSSION_COMMENT,
        commentText,
        ANALYTICS_ITEM_NAMES.COMMENT
      );
      callCommentApi();
    }
  };

  const emptyListView = () => {
    return <DefaultContentView message={ERROR_MESSAGES.EXCHANGE_YOUR_THOUGHTS} />;
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      {showEmptyContentView && !discussionsList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {loadMoreFlag && !!discussionsList.length && (
            <div className="loadMoreContainer">
              <img
                src={getFeatureComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.LOADING_IMAGE,
                  ACCESSIBILITY_IDENTIFIERS.DEAR_EMPLOYER
                )}
                className="load-more-icon mr-2"
                onClick={onPressLoadPrevious}
              />
              <div onClick={onPressLoadPrevious} className="loadmoreText">
                Load previous comments
              </div>
            </div>
          )}
          {discussionsList.map((item, index) => {
            return <DiscussionTabItem key={`${index}`} item={item} />;
          })}
        </div>
      )}
      <CommentBox
        getCommentText={onPressSend}
        onChangehandler={handleChange}
        commentText={commentText}
        textValue="Send"
        showLoader={progressLoadingState.isProgressLoading}
      />
    </div>
  );
};

export default DiscussionTab;
