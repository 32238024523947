import React, { useCallback, useEffect, useRef, useState } from 'react';
import CustomTextInput from '../../../common/ui/custom_text_input/CustomTextInput';
import { getTomorrowDateInYYYYMMDD, showToast } from '../../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { resetLoaderState, saveProgressLoadingState } from '../../../state/UIState';
import { handleError, msApiRequest } from '../../../services/Service';
import { FACILITIES, SEAT_BOOKING_AVAILABLE_SEATS } from '../../../services/ApiUrls';
import {
  BUTTON_TITLES,
  MODAL_TITLES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../common/constants/AppConstants';
import Select from 'react-select';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { Modal } from 'react-bootstrap';
import dayjs from 'dayjs';
import BackButton from '../../../common/ui/common_back_button/BackButton';
import SectionHeader from '../../../common/ui/section_header/SectionHeader';
import { useHistory } from 'react-router-dom';
import { getFacilityData, getFloorData, getSeatData } from '../action';
import { selectSeatBookingMinInterval } from '../../../state/MasterData';
import CustomModal from '../../../common/ui/custom_modal/CustomModal';

const BookASpaceTab = ({ location }) => {
  const qbRoutes = location.state;
  const initialFacilitySet = useRef(false);
  const initialLevelSet = useRef(false);
  const initialSpaceSet = useRef(false);
  const [selectedDate, setSelectedDate] = useState(getTomorrowDateInYYYYMMDD);
  const [checkInTime, setCheckInTime] = useState(qbRoutes?.checkInTime || '');
  const [checkOutTime, setCheckOutTime] = useState(qbRoutes?.checkOutTime || '');
  const minIntervalInMins = useSelector(selectSeatBookingMinInterval);

  const [facilities, setFacilities] = useState([]);
  const [facilitiesDropDownData, setFacilitiesDropDownData] = useState(
    facilities
      ? Object.values(facilities)
          .flat()
          .map((facility) => ({
            label: facility.title,
            value: facility.facility_id,
            link: facility.url,
            address: facility.address,
            floor: facility.floors,
          }))
      : []
  );
  const [selectedFacility, setSelectedFacility] = useState();
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [level, setLevel] = useState([]);
  const [seats, setSeat] = useState([]);
  const [levelsDropDownData, setLevelsDropDownData] = useState(
    level
      ? Object.values(level)
          .flat()
          .map((floor) => ({
            label: floor.name,
            value: floor._id,
            seats: floor.seats,
          }))
      : []
  );
  const [seatsDropDownData, setSeatsDropDownData] = useState(
    seats
      ? Object.values(seats)
          .flat()
          .map((seat) => ({
            label: seat.name,
            value: seat._id,
          }))
      : []
  );
  const [selectedLevel, setSelectedLevel] = useState();
  const [selectedSeat, setSelectedSeat] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const entryDateTime = dayjs(`${selectedDate} ${checkInTime}`).format('YYYY-MM-DD HH:mm:ss');
  const exitDateTime = dayjs(`${selectedDate} ${checkOutTime}`).format('YYYY-MM-DD HH:mm:ss');
  const formattedCheckInTime = dayjs(`${selectedDate} ${checkInTime}`).format('hh:mm A');
  const formattedCheckOutTime = dayjs(`${selectedDate} ${checkOutTime}`).format('hh:mm A');
  const [showEmptyModal, setShowEmptyModal] = useState(false);
  const [formValue, setFormValues] = useState();

  const callDropdownValuesApi = useCallback(async () => {
    try {
      if (!!checkInTime && !!checkOutTime) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        setLevel();
        setLevelsDropDownData();
        const params = {
          org_id: 1,
          entry_datetime: entryDateTime ?? null,
          exit_datetime: exitDateTime ?? null,
          timezone: localTimezone ?? null,
        };
        const apiResponse = await msApiRequest(
          SEAT_BOOKING_AVAILABLE_SEATS,
          REQUEST_TYPE.GET,
          params
        );
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.statusCode === 200) {
            if (apiResponse.response.data) {
              const data = apiResponse.response.data;
              setFormValues(data);
              if (data) {
                const facdropdownData = data.map((facility) => ({
                  label: facility.title,
                  value: facility.facility_id,
                  link: facility.url,
                  address: facility.address,
                  floors: facility.floors,
                }));
                setFacilitiesDropDownData(facdropdownData);
              }
            }
          }
        }
        dispatch(resetLoaderState());
      } else {
        return;
      }
    } catch (err) {
      handleError(err, {}, FACILITIES, NAVIGATION_ROUTES.FACILITY);
      dispatch(resetLoaderState());
    }
  }, [checkInTime, checkOutTime, dispatch, entryDateTime, exitDateTime, localTimezone]);

  const callFacilitiesApi = useCallback(async () => {
    try {
      dispatch(saveProgressLoadingState({ isProgressLoading: true }));
      const apiResponse = await getFacilityData();
      if (apiResponse.response?.status === true) {
        const data = apiResponse.response?.data || {};
        const facilitiesArray = Object.values(data).flat();
        setFacilities(facilitiesArray);
        const dropdownData = facilitiesArray.map((facility) => ({
          label: facility.title,
          value: facility.facility_id,
          link: facility.url,
          address: facility.address,
        }));
        setFacilitiesDropDownData(dropdownData);
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      handleError(err, {}, FACILITIES, NAVIGATION_ROUTES.FACILITY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch]);

  const callLevelApi = useCallback(async () => {
    try {
      if (selectedFacility && selectedFacility?.value) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await getFloorData(
          selectedFacility,
          entryDateTime,
          exitDateTime && exitDateTime,
          localTimezone
        );
        if (apiResponse.response) {
          const data = apiResponse.response?.data?.floors || {};
          const facilitiesArray = Object.values(data).flat();
          setLevel(facilitiesArray);
          const dropdownData = facilitiesArray.map((facility) => ({
            label: facility.name,
            value: facility._id,
            endTime: facility.end_time,
            startTime: facility.start_time,
          }));
          setLevelsDropDownData(dropdownData);
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } else {
        return;
      }
    } catch (err) {
      handleError(err, {}, FACILITIES, NAVIGATION_ROUTES.FACILITY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, entryDateTime, exitDateTime, localTimezone, selectedFacility]);

  const callSeatApi = useCallback(async () => {
    try {
      if (selectedFacility && selectedFacility?.value && selectedLevel && selectedLevel?.value) {
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = await getSeatData(
          selectedFacility,
          selectedLevel,
          entryDateTime,
          exitDateTime && exitDateTime,
          localTimezone
        );
        if (apiResponse.response) {
          const data = apiResponse.response?.seats || {};
          const facilitiesArray = Object.values(data).flat();
          setSeat(facilitiesArray);
          const dropdownData = facilitiesArray.map((facility) => ({
            label: facility.name,
            value: facility._id,
          }));
          setSeatsDropDownData(dropdownData);
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } else {
        return;
      }
    } catch (err) {
      handleError(err, {}, FACILITIES, NAVIGATION_ROUTES.FACILITY);
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  }, [dispatch, entryDateTime, exitDateTime, localTimezone, selectedFacility, selectedLevel]);

  useEffect(() => {
    callDropdownValuesApi();
    // callLevelApi();
    // callSeatApi();
  }, [callDropdownValuesApi, entryDateTime, exitDateTime]);

  useEffect(() => {
    if (
      qbRoutes &&
      qbRoutes?.facilityId &&
      facilitiesDropDownData?.length > 0 &&
      !initialFacilitySet.current
    ) {
      const initialFacility = facilitiesDropDownData?.find(
        (item) => item.value == qbRoutes?.facilityId
      );
      setSelectedFacility(initialFacility);
      initialFacilitySet.current = true;
    }
  }, [qbRoutes, facilitiesDropDownData]);

  useEffect(() => {
    if (
      qbRoutes &&
      qbRoutes?.levelId &&
      selectedFacility?.floors.length > 0 &&
      !initialLevelSet.current
    ) {
      setLevel(selectedFacility?.floors);
      const flrdropdownData = selectedFacility?.floors?.map((level) => ({
        label: level.name,
        value: level._id,
        provisions: level.provisions,
        endTime: level.end_time,
        startTime: level.start_time,
        seats: level.seats,
      }));
      setLevelsDropDownData(flrdropdownData);
      const initialLevel = flrdropdownData?.find((item) => item.value == qbRoutes.levelId);
      setSelectedLevel(initialLevel);
      initialLevelSet.current = true;
    }
  }, [qbRoutes, selectedFacility]);

  useEffect(() => {
    if (
      qbRoutes &&
      qbRoutes?.space_id &&
      selectedLevel?.seats?.length > 0 &&
      !initialSpaceSet.current
    ) {
      setSeat(selectedLevel?.seats);
      const dropdownData = selectedLevel?.seats?.map((seat) => ({
        label: seat.name,
        value: seat._id,
        provisions: seat.provisions,
      }));
      setSeatsDropDownData(dropdownData);
      const initialSpace = dropdownData?.find((item) => item.value == qbRoutes.space_id);
      setSelectedSeat(initialSpace);
      initialSpaceSet.current = true;
    }
  }, [qbRoutes, selectedLevel]);

  // useEffect(() => {
  //   if (qbRoutes?.checkInTime) {
  //     setCheckInTime(qbRoutes?.checkInTime);
  //   }
  //   if (qbRoutes?.checkOutTime) {
  //     setCheckOutTime(qbRoutes?.checkOutTime);
  //   }
  // }, [qbRoutes]);

  const handleCheckoutTimeChange = (e) => {
    const selectedCheckoutTime = e.target.value;

    if (!checkInTime) {
      showToast('Please select a Check-in time first.');
      return;
    }

    const checkInDateTime = dayjs(checkInTime, 'HH:mm');
    const checkOutDateTime = dayjs(selectedCheckoutTime, 'HH:mm');

    if (checkOutDateTime.isBefore(checkInDateTime)) {
      showToast('Check-out time should be after Check-in time.');
      return;
    }

    if (checkOutDateTime.diff(checkInDateTime, 'minute') < minIntervalInMins) {
      showToast(
        `Check-out time should be at least ${minIntervalInMins} minutes later than Check-in time.`
      );
      return;
    }

    if (selectedCheckoutTime !== checkOutTime) {
      setSelectedFacility(null);
      setSelectedLevel(null);
      setSelectedSeat(null);
      setFacilitiesDropDownData([]);
    }
    setCheckOutTime(selectedCheckoutTime);
  };

  const handleFacilityChange = (selectedOption) => {
    setSelectedFacility(selectedOption);
    if (selectedFacility?.value !== selectedOption?.value) {
      setSelectedLevel({
        title: '',
        floor_id: '',
        provisions: [],
        endTime: '',
        startTime: '',
        seats: [],
      });
      setSelectedSeat({ title: '', seat_id: '', provisions: [] });
    }
    setLevel(selectedOption.floors);
    const flrdropdownData = selectedOption.floors?.map((level) => ({
      label: level.name,
      value: level._id,
      provisions: level.provisions,
      endTime: level.end_time,
      startTime: level.start_time,
      seats: level.seats,
    }));
    setLevelsDropDownData(flrdropdownData);
  };

  const handleLevelChange = (selectedOption) => {
    if (selectedLevel?.value !== selectedOption?.value) {
      setSelectedSeat({ title: '', seat_id: '', provisions: [] });
    }
    setSelectedLevel(selectedOption);
    setSeat(selectedOption.seats);
    const dropdownData = selectedOption?.seats?.map((seat) => ({
      label: seat.name,
      value: seat._id,
      provisions: seat.provisions,
    }));
    setSeatsDropDownData(dropdownData);
  };

  const confirmationDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="base-18px-font-bold mt-3">Confirmation</div>
        <div className="base-14px-font mt-1 pt-1">Are you sure?</div>
        <div className="base-14px-font mt-1">You would like to block this parking.</div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center">
          <div
            onClick={() => {
              setShowConfirmationDialog(false);
            }}
            className="base-14px-font-bold base-modal-no-button d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer mr-3">
            NO
          </div>
          <div
            onClick={() => {
              setShowConfirmationDialog(false);
              setShowSuccessDialog(true);
            }}
            className="base-14px-font-bold base-modal-yes-button d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer">
            YES
          </div>
        </div>
      </div>
    );
  };

  const successDialog = () => {
    return (
      <div className="feedback-success-dialog d-flex flex-column align-items-center justify-content-center w-100">
        <div className="base-18px-font-bold mt-3">Submitted successfully!</div>
        <div className="base-14px-font mt-1 pt-1">Your request is approved.</div>
        <div className="d-flex flex-row w-100 align-items-center justify-content-center">
          <div
            onClick={() => {
              setShowSuccessDialog(false);
            }}
            className="base-14px-font-bold base-modal-yes-button d-flex flex-row align-items-center justify-content-center mt-4 align-self-center mb-3 common-cursor-pointer">
            OK
          </div>
        </div>
      </div>
    );
  };

  const isButtonDisabled = () => {
    return (
      !selectedDate ||
      !checkInTime ||
      !checkOutTime ||
      !selectedFacility?.value ||
      !selectedSeat?.value ||
      !seats?.length
    );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (
        selectedFacility &&
        selectedFacility?.value &&
        level &&
        level?.length > 0 &&
        selectedLevel?.value &&
        (!seats || seats?.length === 0)
      ) {
        setShowEmptyModal(true);
      }
    }, 200);
    return () => clearTimeout(timer);
  }, [selectedFacility, seats, level, selectedLevel?.value]);

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    const today = dayjs().startOf('day');

    if (dayjs(newDate).isBefore(today, 'day')) {
      showToast("Past dates can't be selected");
    } else {
      if (newDate !== selectedDate) {
        setCheckInTime('');
        setCheckOutTime('');
        setSelectedFacility();
        setSelectedLevel();
        setSelectedSeat();
        setFacilitiesDropDownData([]);
      }
      setSelectedDate(newDate);
    }
  };

  const handleCheckInTimeChange = (e) => {
    const selectedCheckInTime = e.target.value;
    const selectedEntryDateTime = dayjs(`${selectedDate} ${selectedCheckInTime}`);
    if (selectedEntryDateTime.isBefore(dayjs())) {
      showToast('The selected entry time is in the past. Please choose a future time.');
      return;
    }

    if (selectedCheckInTime !== checkInTime) {
      setCheckOutTime('');
      setSelectedFacility();
      setSelectedLevel();
      setSelectedSeat();
      setFacilitiesDropDownData([]);
    }

    setCheckInTime(selectedCheckInTime);
  };

  return (
    <div className="container">
      <SectionHeader title={'MiDESK'} />
      <BackButton />
      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showConfirmationDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          null;
        }}>
        <Modal.Body>{confirmationDialog()}</Modal.Body>
      </Modal>

      <Modal
        contentClassName="feedback-success-modal mx-auto"
        show={showSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => {
          setShowSuccessDialog(false);
        }}>
        <Modal.Body>{successDialog()}</Modal.Body>
      </Modal>
      <CustomModal
        description={`Please select different date or time.`}
        title={MODAL_TITLES.SEATS_UNAVAILABLE}
        visible={showEmptyModal}
        onClose={() => setShowEmptyModal(false)}
        primaryButtonTitle={BUTTON_TITLES.OK}
        onClickPrimaryButton={() => setShowEmptyModal(false)}
      />
      <div className="mb-3 mt-3">
        Book your seat for next visit to our office for a hassle free experience.
      </div>
      <div className="seat-booking-divider mb-2" />
      <div className="seat-booking-primary-container">
        <div className="base-14px-font ml-1">Date</div>
        <div className="flex-fill mr-2 mb-2">
          <CustomTextInput
            name="destination_pref_date"
            isTitle={false}
            placeholder="Date"
            type="date"
            value={selectedDate}
            onChange={handleDateChange}
          />
        </div>
        <div className="d-flex flex-row justify-content-between">
          <div>
            <div className="base-14px-font ml-1">Check-in time</div>
            <div className="flex-fill">
              <CustomTextInput
                isTitle={false}
                placeholder="Time"
                name="pref_time"
                type="time"
                value={checkInTime}
                onChange={handleCheckInTimeChange}
              />
            </div>
          </div>
          <div>
            <div className="base-14px-font ml-1">Check-out time</div>
            <div className="flex-fill mr-2">
              <CustomTextInput
                isTitle={false}
                placeholder="Time"
                name="pref_time"
                type="time"
                value={checkOutTime}
                onChange={handleCheckoutTimeChange}
              />
            </div>
          </div>
        </div>
        {formValue && formValue?.length > 0 && checkInTime && checkOutTime ? (
          <>
            <div className="base-14px-font ml-1 mb-1">Select Facility</div>
            <div className="flex-fill mr-2 mb-2">
              <Select
                options={facilitiesDropDownData}
                value={selectedFacility}
                onChange={handleFacilityChange}
                placeholder="Select a facility..."
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </>
        ) : (
          <>
            <div className="base-14px-font ml-1 mb-1 sb-disabled-opacity">Select Facility</div>
            <div
              onClick={() => showToast('Please select the previous values first')}
              className="flex-fill mr-2 mb-2 sb-disabled-select">
              Select a facility...
            </div>
          </>
        )}

        {selectedFacility &&
        selectedFacility?.value &&
        level &&
        level?.length > 0 &&
        levelsDropDownData &&
        Object.keys(levelsDropDownData).length > 0 ? (
          <>
            <div className="d-flex flex-row">
              <div className="base-14px-font ml-1 mb-1 mr-1">Select Floor</div>
              {selectedLevel?.startTime && selectedLevel?.endTime && (
                <div className="base-10px-font" style={{ marginTop: 2.5 }}>
                  {`Timing ${dayjs(selectedLevel?.startTime).format('hh:mm A')}-${dayjs(
                    selectedLevel?.endTime
                  ).format('hh:mm A')}`}
                </div>
              )}
            </div>
            <div className="flex-fill mr-2 mb-2">
              <Select
                options={levelsDropDownData}
                value={selectedLevel}
                onChange={handleLevelChange}
                placeholder="Select a floor..."
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </>
        ) : (
          <>
            <div className="base-14px-font ml-1 mb-1 sb-disabled-opacity">Select Floor</div>
            <div
              onClick={() => showToast('Please select the previous values first')}
              className="flex-fill mr-2 mb-2 sb-disabled-select">
              Select a floor...
            </div>
          </>
        )}
        {selectedFacility &&
        selectedFacility?.value &&
        level &&
        level?.length > 0 &&
        selectedLevel?.value &&
        seats &&
        seats?.length > 0 &&
        seatsDropDownData &&
        Object.keys(seatsDropDownData).length > 0 ? (
          <>
            <div className="base-14px-font ml-1 mb-1">Select Seat</div>
            <div className="flex-fill mr-2 mb-2">
              <Select
                options={seatsDropDownData}
                value={selectedSeat}
                onChange={(selectedOption) => setSelectedSeat(selectedOption)}
                placeholder="Select a seat..."
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            </div>
          </>
        ) : (
          <>
            <div className="base-14px-font ml-1 mb-1 sb-disabled-opacity">Select Seat</div>
            <div
              onClick={() => showToast('Please select the previous values first')}
              className="flex-fill mr-2 mb-2 sb-disabled-select">
              Select a seat...
            </div>
          </>
        )}
        <div className="d-flex w-100 justify-content-center mb-2">
          <div className="w-100">
            <CustomButton
              disabled={isButtonDisabled()}
              buttonStyle="estore-add-to-cart-button mt-2"
              onClick={() => {
                history.push(NAVIGATION_ROUTES.SEAT_BOOKING_PREVIEW_SCREEN, {
                  checkInTime: formattedCheckInTime,
                  checkOutTime: formattedCheckOutTime,
                  facility: selectedFacility,
                  level: selectedLevel,
                  parkingNumber: selectedSeat,
                  selectedDate: selectedDate,
                  entryDatetime: entryDateTime,
                  exitDatetime: exitDateTime,
                  space: selectedSeat,
                  space_id: selectedSeat?.value,
                });
              }}>
              CONTINUE
            </CustomButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookASpaceTab;
