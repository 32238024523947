import { msApiRequest } from '../../services/Service';
import { REQUEST_TYPE } from '../../common/constants/AppConstants';

// Helper function to generate request parameters
const createRequestParams = (params) => ({
  org_id: 1,
  ...params,
});

// Helper function to handle API requests with error handling
const safeApiRequest = async (url, requestType, params) => {
  try {
    const response = await msApiRequest(url, requestType, params);
    return response;
  } catch (error) {
    console.error(`Error making request to ${url}:`, error);
    throw new Error(`Request failed: ${error.message}`);
  }
};

export const callBookApi = async (params) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/book',
    REQUEST_TYPE.POST,
    createRequestParams(params)
  );
};

export const getFacilityData = async () => {
  return await safeApiRequest(
    'employee/facilities/list',
    REQUEST_TYPE.GET,
    createRequestParams({ source: 'vehicle' })
  );
};

export const getDashboardFacilityData = async () => {
  return await safeApiRequest(
    'employee/facilities/list',
    REQUEST_TYPE.GET,
    createRequestParams({ type: 'dashboard', source: 'vehicle' })
  );
};

export const getLevelData = async (selectedFacility, entry_datetime, exit_datetime, timezone) => {
  const facilityId = selectedFacility?.value ?? null;
  return await safeApiRequest(
    'vehicle-parking/levels/get',
    REQUEST_TYPE.GET,
    createRequestParams({
      facility_id: facilityId,
      entry_datetime: entry_datetime ?? null,
      exit_datetime: exit_datetime ?? null,
      timezone: timezone ?? null,
    })
  );
};

export const getSpaceData = async (
  selectedFacility,
  selectedFloor,
  entryTime,
  exitTime,
  timezone
) => {
  const facilityId = selectedFacility?.value ?? null;
  const levelId = selectedFloor?.value ?? null;
  return await safeApiRequest(
    'vehicle-parking/spaces',
    REQUEST_TYPE.GET,
    createRequestParams({
      facility_id: facilityId,
      level_id: levelId,
      entry_datetime: entryTime ?? null,
      exit_datetime: exitTime ?? null,
      timezone: timezone ?? null,
    })
  );
};

export const getUpcomingBookingApi = async (employeeId) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/get/' + employeeId,
    REQUEST_TYPE.GET,
    createRequestParams({
      type: 'dashboard',
      tab_type: 'upcoming',
      page_id: 1,
    })
  );
};

export const getQuickBookingApi = async (employeeId) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/get/' + employeeId,
    REQUEST_TYPE.GET,
    createRequestParams({
      type: 'dashboard',
      tab_type: 'history',
      page_id: 1,
    })
  );
};

export const getTipsApi = async () => {
  return await safeApiRequest(
    'vehicle-parking/helpful-tips',
    REQUEST_TYPE.GET,
    createRequestParams()
  );
};

export const callCancelApi = async (bookingId, employeeId) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/cancel/' + bookingId + '/' + employeeId,
    REQUEST_TYPE.DELETE,
    createRequestParams()
  );
};

export const getGallery = async (facilityId) => {
  return await safeApiRequest(
    'vehicle-parking/gallery/fetchByFacilityId/' + facilityId,
    REQUEST_TYPE.GET,
    createRequestParams()
  );
};

export const getBookingDetail = async (bookingId) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/details/' + bookingId,
    REQUEST_TYPE.GET,
    createRequestParams()
  );
};

export const callBookingsApi = async (employeeId, get_params) => {
  return await safeApiRequest(
    'vehicle-parking/bookings/get/' + employeeId,
    REQUEST_TYPE.GET,
    createRequestParams(get_params)
  );
};

export const getLevelDetail = async (levelId) => {
  return await safeApiRequest(
    'vehicle-parking/levels/' + levelId,
    REQUEST_TYPE.GET,
    createRequestParams()
  );
};

export const getSpaceDetail = async (spaceId) => {
  return await safeApiRequest(
    'vehicle-parking/spaces/' + spaceId,
    REQUEST_TYPE.GET,
    createRequestParams()
  );
};
