import { createSlice } from '@reduxjs/toolkit';

const saveBookingDataReducer = (state, action) => {
  state.selectedType = action.payload; // Or update state.bookings based on what you're trying to achieve
};

// REDUCER
const bookingDataSlice = createSlice({
  name: 'seatData',
  initialState: {
    selectedType: 'upcoming',
  },
  reducers: {
    saveBookingData: saveBookingDataReducer,
  },
});

// ACTIONS
const { saveBookingData } = bookingDataSlice.actions;

// SELECTOR
const selectBookingData = ({ seatData }) => seatData.selectedType;

const bookingDataSliceReducer = bookingDataSlice.reducer;

export { bookingDataSliceReducer, saveBookingData, selectBookingData };
