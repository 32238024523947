import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  ACCESSIBILITY_FEATURE_COMPONENTS,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  ML_DAY_IDENTIFIERS,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
  SUPPORTED_IMAGE_FORMATS,
  SUPPORTED_IMAGE_FORMATS_WEB,
  WARNING_MESSAGES,
} from '../../../common/constants/AppConstants';
import { GET_GALLERY_LIST, UPLOAD_BREAKOUT_IMAGES } from '../../../services/ApiUrls';
import { apiRequest, handleError } from '../../../services/Service';
import { selectAccessibilityData } from '../../../state/AccessibilityData';
import { getFeatureComponentUrl, getHeaderComponentUrl } from '../../../utils/accessibility';
import GalleryTabItem from '../components/GalleryTabItem';
import { Modal } from 'react-bootstrap';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectShowEmptyContentView,
} from '../../../state/UIState';
import CustomButton from '../../../common/ui/custom_button/CustomButton';
import { formatDateDDMonthYYYY, showToast } from '../../../utils/common';
import DefaultContentView from '../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../utils/FirebaseAnalyticsUtils';
import ActivityLoader from '../../../common/ui/activity_loader/ActivityLoader';
import { selectAnniversaryConfig, selectMaxFileUploadSizeConfig } from '../../../state/MasterData';
import { uploadDocuments } from '../../../utils/commonApiRequests';
import CommonGalleryItem from '../../../common/components/CommonGalleryItem';

const GalleryTab = ({ breakoutId, identifier }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [galleryList, setGalleryList] = useState([]);
  const [baseUrl, setBaseUrl] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isRecent, setRecent] = useState(false);
  const [noOfPhotos, setNoOfPhotos] = useState('');
  const [showUploadImageDialog, setShowUploadImageDialog] = useState(false);
  const [showImageUploadSuccessDialog, setShowImageUploadSuccessDialog] = useState(false);
  const accessibilityData = useSelector(selectAccessibilityData);
  const showEmptyContentView = useSelector(selectShowEmptyContentView);
  const maxFileUploadSizeInMB = useSelector(selectMaxFileUploadSizeConfig);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesLength, setSelectedImagesLength] = useState(0);
  const anniversaryConfig = useSelector(selectAnniversaryConfig);

  const selectedAnniversaryConfig = identifier
    ? anniversaryConfig.find((element) => element.identifier === identifier)
    : [];

  const selectImagesInputRef = useRef(null);

  useEffect(() => {
    !identifier
      ? logEvent(
          ANALYTICS_EVENT_TYPES.BREAKOUTS_GALLERY_TAB_VISIT,
          breakoutId,
          ANALYTICS_ITEM_NAMES.BREAKOUTS
        )
      : logEvent(
          ANALYTICS_EVENT_TYPES.ANNIVERSARY_GALLERY_TAB_VISIT,
          identifier,
          ANALYTICS_ITEM_NAMES.ANNIVERSARY
        );
  }, [breakoutId, identifier]);

  const callGalleryApi = useCallback(
    async (recent) => {
      dispatch(saveShowEmptyContentView(true));
      const params = {
        recent: recent,
      };
      try {
        setLoading(true);
        const apiResponse = identifier
          ? await apiRequest(identifier + '/gallery', REQUEST_TYPE.GET, params)
          : await apiRequest(GET_GALLERY_LIST + breakoutId, REQUEST_TYPE.GET, params);
        setLoading(false);
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            const dataArr = [];
            for (const property in apiResponse.response.data.feeds.pictures) {
              dataArr.push({
                title: property,
                data: apiResponse.response.data.feeds.pictures[property],
              });
            }
            setGalleryList(dataArr);
            setBaseUrl(apiResponse.response.data.feeds.base_url);
            setNoOfPhotos(apiResponse.response.data.count);
          }
        }
      } catch (err) {
        handleError(err, params, GET_GALLERY_LIST + breakoutId, NAVIGATION_ROUTES.BREAKOUTS);
        setLoading(false);
      }
    },
    [breakoutId, dispatch, identifier]
  );

  useEffect(() => {
    callGalleryApi(isRecent);
  }, [callGalleryApi, isRecent]);

  const onPressMore = (item, url) => {
    history.push(
      identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER
        ? NAVIGATION_ROUTES.ANNIVERSAY_GALLERY_SCREEN
        : NAVIGATION_ROUTES.BREAKOUT_GALLERY_SCREEN,
      {
        data: item.data,
        baseUrl: url,
        breakoutId: breakoutId,
        identifier: identifier,
      }
    );
  };

  const onPressItem = (item, index) => {
    history.push(NAVIGATION_ROUTES.GALLERY_FULL_IMAGE_SCREEN, {
      baseUrl: baseUrl,
      cards: item,
      cardIndex: index,
      identifier: identifier,
    });
  };

  const onPressRecent = () => {
    setGalleryList([]);
    if (isRecent === true) {
      setRecent(false);
      callGalleryApi(false);
    } else {
      setRecent(true);
      callGalleryApi(true);
    }
  };

  const onPressUploadPhotos = () => {
    setShowUploadImageDialog(true);
  };

  const callUploadImagesApi = useCallback(
    async (images) => {
      setShowUploadImageDialog(false);
      try {
        const params = new URLSearchParams();
        params.append('files', JSON.stringify(images));
        dispatch(saveProgressLoadingState({ isProgressLoading: true }));
        const apiResponse = identifier
          ? await apiRequest(identifier + '/upload', REQUEST_TYPE.POST, params)
          : await apiRequest(
              UPLOAD_BREAKOUT_IMAGES + breakoutId + '/upload',
              REQUEST_TYPE.POST,
              params
            );
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        if (Object.keys(apiResponse).length > 0) {
          if (apiResponse.response.status === true) {
            setSelectedImages([]);
            setSelectedImagesLength(0);
            showToast(apiResponse.response.message);
            setShowImageUploadSuccessDialog(true);
          }
        }
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      } catch (err) {
        dispatch(saveProgressLoadingState({ isProgressLoading: false }));
      }
    },
    [breakoutId, dispatch, identifier]
  );

  useEffect(() => {
    if (selectedImages.length === selectedImagesLength && selectedImagesLength !== 0) {
      callUploadImagesApi(selectedImages);
    }
  }, [callUploadImagesApi, selectedImages, selectedImagesLength]);

  const imageUploadDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_UPLOAD_PHOTO,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon common-cursor-pointer"
            onClick={(e) => {
              e.stopPropagation();
              selectImagesInputRef.current.click();
            }}
          />
          <input
            type="file"
            onChange={onFileChange}
            accept={SUPPORTED_IMAGE_FORMATS}
            id="input"
            ref={selectImagesInputRef}
            multiple
            onClick={(event) => {
              event.target.value = null;
            }}
            className="d-none"
          />
        </div>
        <div
          onClick={(e) => {
            e.stopPropagation();
            selectImagesInputRef.current.click();
          }}
          className="text-center selectImagesToUpload mt-2 common-cursor-pointer">
          Select images to be uploaded
        </div>
        <div className="text-center imagesReview mt-2 mb-3">
          All images to be reviewed by MicrolandOne App Admin.
        </div>
      </div>
    );
  };

  const onPressViewGallery = () => {
    setShowImageUploadSuccessDialog(false);
    setGalleryList([]);
    callGalleryApi(isRecent);
  };

  const imageUploadSuccessDialog = () => {
    return (
      <div>
        <div className="breakouts-team-micro-view ">
          <img
            src={getFeatureComponentUrl(
              accessibilityData,
              ACCESSIBILITY_FEATURE_COMPONENTS.MICROGIVE_SUCCESS,
              ACCESSIBILITY_IDENTIFIERS.MICROGIVE
            )}
            className="breakouts-upload-icon mr-4"
          />
        </div>
        <div className="breakouts-images-got-uploaded text-center mt-2">Images got uploaded</div>
        <div className="text-center mt-2 breakouts-images-under-review">
          Your Images are under review
        </div>
        <div className="text-center mt-2 breakouts-images-reviewed-by">
          All images to be reviewed by MLOne App Admin.
        </div>
        <CustomButton
          buttonStyle="breakouts-button-style mt-3"
          onClick={onPressViewGallery}
          showLoader={loading}>
          View Gallery
        </CustomButton>
      </div>
    );
  };

  const onFileChange = (event) => {
    const images = [...event.target.files];
    let isAllFilesSupported = true;
    let isAnyFileSizeLarge = false;
    [...event.target.files].forEach((element) => {
      if (!SUPPORTED_IMAGE_FORMATS_WEB.includes(element.type)) {
        isAllFilesSupported = false;
      }
      if (Number(element.size) / (1024 * 1024) > maxFileUploadSizeInMB) {
        isAnyFileSizeLarge = true;
      }
    });
    if (!isAllFilesSupported) {
      showToast(WARNING_MESSAGES.FILE_NOT_SUPPORTED);
      setShowUploadImageDialog(false);
    } else if (isAnyFileSizeLarge) {
      showToast(WARNING_MESSAGES.FILE_SIZE_EXCEEDED(maxFileUploadSizeInMB));
      setShowUploadImageDialog(false);
    } else {
      if (identifier && images?.length > (selectedAnniversaryConfig?.images_limit ?? 5)) {
        showToast(
          WARNING_MESSAGES.MAXIMUM_NO_OF_IMAGE_FILES(selectedAnniversaryConfig?.images_limit ?? 5)
        );
        return;
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      setSelectedImagesLength(event.target.files?.length);
      [...event.target.files].map((image) => {
        uploadDocuments(
          image,
          identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER ? 'anniversary' : 'breakouts'
        )
          .then((response) => {
            setSelectedImages((prev) => [...prev, { filename: response.fileName, type: 'image' }]);
          })
          .catch((message) => {
            showToast(message);

            dispatch(
              saveProgressLoadingState({
                isProgressLoading: false,
              })
            );
          });
      });
    }
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={
          identifier === ML_DAY_IDENTIFIERS.ML_35_IDENTIFIER
            ? ERROR_MESSAGES.ML_35_GALLERY_NO_IMAGE
            : ERROR_MESSAGES.BREAKOUTS_GALLERY_NO_IMAGE
        }
      />
    );
  };

  return (
    <div className="main-content-container">
      <ActivityLoader visible={loading} />
      <Modal
        show={showUploadImageDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={() => setShowUploadImageDialog(false)}>
        <Modal.Body>{imageUploadDialog()}</Modal.Body>
      </Modal>
      <Modal
        show={showImageUploadSuccessDialog}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onHide={onPressViewGallery}>
        <Modal.Body>{imageUploadSuccessDialog()}</Modal.Body>
      </Modal>
      <div className="d-flex flex-row-reverse mi-hr-activity-btn-container">
        <CustomButton
          buttonStyle={`btn-load-more common-cursor-pointer`}
          onClick={onPressUploadPhotos}>
          Upload Photos
        </CustomButton>
      </div>
      {galleryList.length > 0 && (
        <div className="breakouts-date-view-style justify-content-between">
          <div className="breakouts-no-of-photos">{`${noOfPhotos} ${
            noOfPhotos === 1 ? 'Photo' : 'Photos'
          }`}</div>
          <div onClick={onPressRecent} className="breakouts-outer-view common-cursor-pointer">
            <div className="breakouts-date-recent-text">{isRecent ? 'Date' : 'Recent'}</div>
            {isRecent ? (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_CALENDER_FILTER,
                  ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
                )}
                className="breakouts-normal-icon ml-1"
              />
            ) : (
              <img
                src={getHeaderComponentUrl(
                  accessibilityData,
                  ACCESSIBILITY_FEATURE_COMPONENTS.BREAKOUTS_RECENT_FILTER,
                  ACCESSIBILITY_IDENTIFIERS.BREAKOUTS
                )}
                className="breakouts-normal-icon ml-1"
              />
            )}
          </div>
        </div>
      )}
      {showEmptyContentView && !galleryList.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          {galleryList.map((item, index) => {
            return (
              <CommonGalleryItem
                title={item.title !== 'recent' ? formatDateDDMonthYYYY(item.title) : item.title}
                item={item.data}
                baseUrl={baseUrl}
                onPressMore={onPressMore}
                onPressItem={onPressItem}
                key={`${index}`}
                apiUrl={identifier ? identifier + '/gallery/detail' : GET_GALLERY_LIST + 'detail'}
                featureParams={
                  identifier
                    ? { recent: false, date: item.title }
                    : { breakout_id: breakoutId, date: item.title }
                }
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GalleryTab;
