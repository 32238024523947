import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  FEATURE_TYPE,
  LOADING_MESSAGES,
  REQUEST_TYPE,
  NAVIGATION_ROUTES,
  BUTTONTEXT,
  ACCESSIBILITY_IDENTIFIERS,
  ANALYTICS_SCREEN_NAMES,
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  SUCCESS_MESSAGES,
  SCREEN_DENSITY_QUALIFIERS,
} from '../../common/constants/AppConstants';
import CustomButton from '../../common/ui/custom_button/CustomButton';
import HtmlView from '../../common/ui/html_view/HTMLView';
import SectionHeader from '../../common/ui/section_header/SectionHeader';
import { CONTESTS_BY_ID } from '../../services/ApiUrls';
import { apiRequest, handleError } from '../../services/Service';
import { saveProgressLoadingState, selectProgressLoadingState } from '../../state/UIState';
import {
  formatDateDDMonthYYYY,
  formatDateDDthMonthYYYY,
  formatHHMMSSTimeAMPM,
  getUrlWithSpecificRendition,
  showToast,
} from '../../utils/common';
import { useParams } from 'react-router-dom';
import { logEvent, trackScreen } from '../../utils/FirebaseAnalyticsUtils';
import BackButton from '../../common/ui/common_back_button/BackButton';
import { useLocation } from 'react-router';
import { selectAccessData } from '../../state/UserAccessData';

const ContestContentScreen = ({ history }) => {
  const progressLoadingState = useSelector(selectProgressLoadingState);
  const { contestId } = useParams();
  const dispatch = useDispatch();
  const [contests, setContests] = useState();
  const [showButton, setShowButton] = useState(false);
  const [buttonText, setButtonText] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const { search } = useLocation();
  const [enablePosts, setEnablePosts] = useState(0);
  const accessData = useSelector(selectAccessData);
  const [showRegisterButton, setShowRegisterButton] = useState(true);
  const contestsAccessData = accessData?.data?.find((item) => item.feature_key === '_contests');

  useEffect(() => {
    if (contestsAccessData) {
      const actions = contestsAccessData?.actions;
      setShowRegisterButton(actions?.includes('_register'));
    }
  }, [contestsAccessData]);

  useEffect(() => {
    trackScreen(ANALYTICS_SCREEN_NAMES.CONTEST_DETAIL, NAVIGATION_ROUTES.CONTEST_DETAIL);
  }, []);

  useEffect(() => {
    setShowButton(false);
    if (contests) {
      if (contests.gallary.pictures.length > 0) {
        setButtonText(BUTTONTEXT.VIEW_GALLERY);
        setButtonDisable(false);
        setShowButton(true);
      }
      if (contests.participants.allowed_register) {
        if (contests.participants.is_registered) {
          setButtonText(BUTTONTEXT.ALREADY_REGISTER);
          setButtonDisable(true);
          setShowButton(true);
        } else {
          setButtonText(BUTTONTEXT.REGISTER);
          setButtonDisable(false);
          setShowButton(true);
        }
      }
    }
  }, [contests]);

  const callContests = useCallback(async () => {
    try {
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: true,
        })
      );
      logEvent(ANALYTICS_EVENT_TYPES.CONTESTS_BY_ID, contestId, ANALYTICS_ITEM_NAMES.CONTESTS);
      const apiResponse = await apiRequest(CONTESTS_BY_ID + contestId, REQUEST_TYPE.GET, {});
      if (Object.keys(apiResponse).length > 0) {
        if (apiResponse.response.status === true) {
          if (apiResponse.response.data.contest) {
            const data = apiResponse.response.data.contest;
            const enablePostFlag = apiResponse.response.data.enable_posts;
            setEnablePosts(enablePostFlag);
            setContests(data);
          }
        }
      }
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    } catch (err) {
      handleError(err, {}, CONTESTS_BY_ID + contestId, NAVIGATION_ROUTES.CONTESTS_DETAIL_SCREEN);
      dispatch(
        saveProgressLoadingState({
          isProgressLoading: false,
        })
      );
    }
  }, [dispatch, contestId]);

  const callPostRegister = async () => {
    dispatch(
      saveProgressLoadingState({
        isProgressLoading: true,
        progressMessage: LOADING_MESSAGES.EVENT_REGISTER,
      })
    );
    logEvent(ANALYTICS_EVENT_TYPES.CONTESTS_REGISTER, contestId, '');
    const formData = new URLSearchParams();
    formData.append('type', FEATURE_TYPE.EVENTS);
    try {
      const commentApiResponse = await apiRequest(
        CONTESTS_BY_ID + contestId + '/participate',
        REQUEST_TYPE.POST,
        formData
      );
      if (Object.keys(commentApiResponse).length > 0) {
        if (commentApiResponse.response.status === true) {
          showToast(SUCCESS_MESSAGES.CONTEST_REGISTER_SUCESS);
          callContests();
        }
      }
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    } catch (err) {
      handleError(
        err,
        formData,
        CONTESTS_BY_ID + contestId + '/participate',
        NAVIGATION_ROUTES.CONTESTS_DETAIL_SCREEN
      );
      dispatch(saveProgressLoadingState({ isProgressLoading: false }));
    }
  };

  const callButtonFunction = () => {
    if (contests) {
      if (contests.participants.allowed_register && !contests.participants.is_registered) {
        callPostRegister();
      } else if (contests.gallary.pictures.length > 0) {
        history.push(NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN, {
          baseUrl: contests?.gallary.base_url,
          data: contests?.gallary.pictures,
          title: contests?.title,
          apiUrl: 'contests/gallery/detail',
          featureParams: { contest_id: contestId },
        });
      }
    }
  };

  const renderScreen = useCallback(async () => {
    callContests();
  }, [callContests]);

  useEffect(() => {
    renderScreen();
  }, [renderScreen]);

  const viewPostsHandler = () => {
    const searchParams = new URLSearchParams(search);
    searchParams.set('search', contests.post_search_string);
    searchParams.set('upcoming', !contests.participants.past_contest);

    history.push({
      pathname: NAVIGATION_ROUTES.THE_WALL,
      search: searchParams.toString(),
    });
  };

  return (
    <div className="main-content-container container">
      <SectionHeader identifier={ACCESSIBILITY_IDENTIFIERS.CONTESTS} />
      {contests && (
        <div className="header-view pt-0 mb-2 mt-3 justify-content-between">
          <div>
            <BackButton isFullRow={false} />
          </div>
          <div className="row pr-3">
            {contests && contests.participants.allowed_register && showRegisterButton && (
              <div
                className={
                  showButton
                    ? 'pr-5 d-flex align-self-center align-items-center'
                    : 'd-flex align-self-center align-items-center'
                }>
                {contests.participants.max_limit - contests.participants.total_register === 1
                  ? `${
                      contests.participants.max_limit - contests.participants.total_register
                    } seat left`
                  : `${
                      contests.participants.max_limit - contests.participants.total_register
                    } seats left`}
              </div>
            )}
            {showButton && showRegisterButton && (
              <CustomButton
                buttonStyle={
                  buttonDisable ? 'event_btn_disabled_style' : 'contests-upload-photos-btn'
                }
                onClick={callButtonFunction}
                disabled={buttonDisable}
                showLoader={progressLoadingState.isProgressLoading}>
                {buttonText}
              </CustomButton>
            )}
          </div>
        </div>
      )}
      {contests && (
        <div>
          <div>
            <img
              src={getUrlWithSpecificRendition(
                contests.promo_image.base_url,
                SCREEN_DENSITY_QUALIFIERS.XXXHDPI,
                contests.promo_image.image_path
              )}
              className="embed-responsive embed-responsive-16by9"
            />
          </div>
          <div className="contests-title">{contests.title}</div>
          {contests?.start_date && contests?.end_date && (
            <div className="contests-description-text">
              {contests?.start_date === contests?.end_date ? 'Date' : 'Start date'} :{' '}
              {formatDateDDthMonthYYYY(contests?.start_date)}
            </div>
          )}
          {/* Show end date only when the start and end date vary */}
          {!(contests?.start_date === contests?.end_date) && (
            <div className="contests-description-text">
              End date : {formatDateDDthMonthYYYY(contests?.end_date)}
            </div>
          )}
          {!(contests?.start_time === contests?.end_time) && (
            <div className="contests-description-text">
              Timings :{' '}
              {`${formatHHMMSSTimeAMPM(contests?.start_time)} - ${formatHHMMSSTimeAMPM(
                contests?.end_time
              )}`}
            </div>
          )}
          {contests.participants.allowed_register && contests.participants.last_date && (
            <div className="contests-description-text">
              Last date of registration : {formatDateDDMonthYYYY(contests.participants.last_date)}
            </div>
          )}
          {contests.contest_location && (
            <div className="contests-description-text">Venue : {contests.contest_location}</div>
          )}
          {contests.description !== '' && (
            <HtmlView html={contests.description} htmlStyle="mt-3 contests-content-html" />
          )}
          {contests.show_posts && enablePosts === 1 && (
            <div className="d-flex justify-content-center my-4 pt-3 pb-1">
              <CustomButton buttonStyle={'common-custom-button-primary'} onClick={viewPostsHandler}>
                {'View Posts'}
              </CustomButton>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ContestContentScreen;
