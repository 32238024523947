import React from 'react';
import HtmlView from '../../../common/ui/html_view/HTMLView';

const HelpfulTipsItem = ({ item, index }) => {
  return (
    <div className={index != 0 ? 'my-2 ' : ''}>
      <div className="d-flex flex-row">
        <img src={`${item.icon_path}`} className="seat-booking-office-location-icon mr-2" />
        <div>
          <div className="base-16px-font">{item.title}</div>
          <HtmlView html={item.description} htmlStyle="base-10px-font" />
        </div>
      </div>
    </div>
  );
};

export default HelpfulTipsItem;
