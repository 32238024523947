import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ANALYTICS_EVENT_TYPES,
  ANALYTICS_ITEM_NAMES,
  ERROR_MESSAGES,
  NAVIGATION_ROUTES,
  REQUEST_TYPE,
} from '../../../../common/constants/AppConstants';
import { apiRequest, handleError } from '../../../../services/Service';
import { VscChromeClose } from 'react-icons/vsc';
import LoadMoreBtn from '../../../../common/ui/load_more_btn/LoadMoreBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveProgressLoadingState,
  saveShowEmptyContentView,
  selectProgressLoadingState,
} from '../../../../state/UIState';
import DefaultContentView from '../../../../common/ui/default_content_view/DefaultContentView';
import { logEvent } from '../../../../utils/FirebaseAnalyticsUtils';
import AnniversaryGalleryListItem from '../../components/AnniversaryGalleryListItem';
import { Modal } from 'react-bootstrap';
import ReactPlayer from 'react-player/youtube';
import ActivityLoader from '../../../../common/ui/activity_loader/ActivityLoader';

const AnniversaryGalleryTab = ({ identifier }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [gallery, setGallery] = useState([]);
  const [selectedType, setSelectedType] = useState('images');
  const [pageId, setPageId] = useState(1);
  const [loadMoreFlag, setLoadMoreFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  const currentVideo = useRef('');

  const handleClose = () => {
    setShow(false);
    currentVideo.current = '';
  };
  const handleShow = (item) => {
    currentVideo.current = item?.video_link_path;
    setShow(true);
  };
  const progressLoadingState = useSelector(selectProgressLoadingState);

  useEffect(() => {
    logEvent(
      ANALYTICS_EVENT_TYPES.ANNIVERSARY_GALLERY_TAB_VISIT,
      identifier,
      ANALYTICS_ITEM_NAMES.ANNIVERSARY
    );
  }, [identifier]);

  const callGalleryApi = useCallback(
    async (id, loadMore, type = selectedType) => {
      dispatch(saveShowEmptyContentView(true));
      setLoading(true);
      if (loadMore) {
        const params = {
          page_id: id,
          type: type,
        };
        try {
          dispatch(saveProgressLoadingState({ isProgressLoading: true }));
          const apiResponse = await apiRequest(identifier + '/galleries', REQUEST_TYPE.GET, params);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
          if (Object.keys(apiResponse).length > 0) {
            if (apiResponse.response.status === true) {
              if (apiResponse.response.data.length > 0) {
                id === 1
                  ? setGallery(apiResponse.response.data)
                  : setGallery((oldArray) => [...oldArray, ...apiResponse.response.data]);
                setPageId((prev) => prev + 1);
                setLoadMoreFlag(true);
              } else {
                setLoadMoreFlag(false);
              }
            }
          }
          setLoading(false);
        } catch (err) {
          handleError(err, params, identifier + '/galleries', NAVIGATION_ROUTES.ANNIVERSARY_SCREEN);
          setLoading(false);
          dispatch(saveProgressLoadingState({ isProgressLoading: false }));
        }
      }
    },
    [dispatch, identifier, selectedType]
  );

  useEffect(() => {
    callGalleryApi(1, true);
  }, [callGalleryApi]);

  const onPressItem = (item, index) => {
    if (selectedType === 'videos') {
      handleShow(item);
    } else {
      history.push(NAVIGATION_ROUTES.COMMON_GALLERY_FULL_SCREEN, {
        baseUrl: item.base_url + 'images_ml33/images/',
        data: item.gallery,
        title: item.title,
        apiUrl: identifier + '/galleries/detail',
        featureParams: { gallery_id: item?.gallery_id },
      });
    }
  };

  const onPressLoadMore = () => {
    callGalleryApi(pageId, loadMoreFlag);
  };

  const renderTypeTabSwitch = () => {
    return (
      <div className="d-flex mb-3 anniversary-day-outer-view">
        {selectedType === 'images' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill anniversary-left-selected">
            <div className="anniversary-left-selected-text">Images</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              setPageId(1);
              setLoadMoreFlag(true);
              setGallery([]);
              setSelectedType('images');
              callGalleryApi(1, true, 'images');
            }}>
            <div className="anniversary-right-selected-text">Images</div>
          </div>
        )}
        {selectedType === 'videos' ? (
          <div className="d-flex align-items-center justify-content-center flex-fill anniversary-right-selected">
            <div className="anniversary-left-selected-text">Videos</div>
          </div>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center flex-fill common-cursor-pointer"
            onClick={() => {
              setPageId(1);
              setLoadMoreFlag(true);
              setGallery([]);
              setSelectedType('videos');
              callGalleryApi(1, true, 'videos');
            }}>
            <div className="anniversary-right-selected-text">Videos</div>
          </div>
        )}
      </div>
    );
  };

  const emptyListView = () => {
    return (
      <DefaultContentView
        message={
          selectedType !== 'videos'
            ? ERROR_MESSAGES.NO_IMAGES_TO_SHOW
            : ERROR_MESSAGES.NO_VIDEOS_TO_SHOW
        }
      />
    );
  };

  return (
    <div className="main-content-container">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="home-fullscreen-modal-dialog fade-scale"
        aria-labelledby="example-custom-modal-styling-title">
        <Modal.Body className="animate-bottom">
          <div
            className="home-fullscreen-modal-dialog-close-button-container"
            onClick={handleClose}>
            <VscChromeClose size="4em" color="#fff" />
          </div>

          <ReactPlayer
            url={currentVideo.current}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            className="home-video-container"
          />
        </Modal.Body>
      </Modal>
      <div className="d-flex justify-content-center">{renderTypeTabSwitch()}</div>
      {!gallery?.length && !loading ? (
        emptyListView()
      ) : (
        <div>
          <div>
            <ActivityLoader visible={loading} />
          </div>
          <div className="row">
            {gallery.map((item, index) => {
              return (
                <AnniversaryGalleryListItem
                  item={item}
                  baseUrl={item.base_url}
                  onPress={onPressItem}
                  key={`${index}_gallery_item`}
                />
              );
            })}
          </div>
          {loadMoreFlag && (
            <LoadMoreBtn
              onClick={onPressLoadMore}
              isLoading={progressLoadingState.isProgressLoading}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default AnniversaryGalleryTab;
