import React from 'react';

const StatusIndicator = ({ status }) => {
  return (
    <div>
      {status &&
        (status === 'Pending' ? (
          <div className="status-indicator-container status-point-pending">
            <div className="status-text-pending">{status}</div>
          </div>
        ) : status === 'Approved' || status === 'Booked' || status === 'Completed' ? (
          <div className="status-indicator-container status-point-approved">
            <div className="status-text-approved">{status}</div>
          </div>
        ) : status === 'Expired' ? (
          <div className="status-indicator-container status-point-expired">
            <div className="status-text-expired">{status}</div>
          </div>
        ) : (
          <div className="status-indicator-container status-point-rejected">
            <div className="status-text-rejected">{status}</div>
          </div>
        ))}
    </div>
  );
};

export default StatusIndicator;
